@import "../../assets/variableStyles.scss";

.flight-ticket {
  padding-bottom: 10px;
  .collapsed-data {
    padding: 0;
  }
  .ant-collapse-item {
    .ant-collapse-content {
      border: none;
    }
  }

  .actionable-buttons {
    max-width: 1100px;
    margin: auto;
    .trip-type-selector {
      background-color: #fff;
      padding: 0 10px;
      p {
        text-align: start;
        &:hover {
          color: #1890ff;
        }
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
      span {
        color: #000;
      }
    }
    .ant-collapse-content-active {
      border-bottom: 1px solid #d9d9d9;
    }
    .ant-collapse-content {
      border: transparent;
    }
    .ant-collapse-content-box {
      padding: 16px 0;
    }
    .ant-collapse-item {
      margin-bottom: 15px;
      background: white;
      border-bottom: 1px solid #d9d9d9;

      .ant-collapse-header {
        width: auto;
        text-align: start;
        font-weight: 600;
        padding: 10px 15px;
        border-bottom: 1px solid #d9d9d9;
        &:hover {
          color: #1890ff;
        }
        .ant-collapse-arrow {
          display: block !important;
          width: 100%;
          text-align: end;
          left: -15px;
        }
      }
    }
  }
}

.fligh-ticket-container {
  max-width: 1200px;
  margin: auto;
  padding: 15px 50px;
  @include mobile {
    padding: 15px 0px;
  }

  .additional_services {
    border: 1px solid #000;
    padding: 10px 15px;
  }
  .passenger-details {
    text-align: start;
    p {
      font-weight: bold;
      color: #000;
      span {
        font-weight: normal;
      }
    }
  }
}
.flight-ticket-header {
  display: flex;
  justify-content: space-between;
  .logo {
    h4 {
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .travel-company-details {
    ul {
      text-align: end;
      .travel-company-name {
        font-weight: bold;
        font-size: 16px;
        color: #000;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .travel-phone-number {
        font-weight: bold;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
.booking-flight-details {
  border: 1px solid #000;
  .flight-ref-details {
    padding: 15px 10px;
    border-bottom: 1px solid #000;
    ul {
      display: flex;
      justify-content: space-between;
      @include mobile {
        display: block;
      }
      li {
        font-weight: bold;
        color: #000;
        @include mobile {
          margin-bottom: 10px;
          text-align: start;
        }
        span {
          font-weight: normal;
          margin-left: 15px;
        }
      }
    }
  }
  .flight-fare-details {
    padding: 15px 10px;
    border-bottom: 1px solid #000;
    ul {
      display: flex;
      justify-content: space-between;
      @include mobile {
        display: block;
      }
      li {
        font-weight: bold;
        color: #000;
        @include mobile {
          margin-bottom: 10px;
          text-align: start;
        }
        span {
          margin-left: 15px;
        }
      }
    }
  }
  .trip-details {
    padding: 15px 10px;

    ul {
      display: flex;
      justify-content: space-between;
      @include mobile {
        display: block;
      }
      li {
        font-weight: bold;
        color: #000;
        /* width: 46%;
        display: flex;
        align-items: center; */
        @include mobile {
          margin-bottom: 10px;
          width: 100%;
        }
        span {
          margin-left: 15px;
        }
        .flight-from-to {
          display: flex;
          flex-direction: column;
          // margin-left: 15px;
        }
        p {
          font-weight: normal;
          margin-bottom: 0;
          span {
            font-weight: bold;
            color: #000;
            margin-left: 0;
          }
        }
      }
    }
  }
}
.booking-flight-details-info {
  border: 1px solid #000;
  margin: 15px 0;
  .flight-deatils-text {
    padding: 15px 10px;
    text-align: center;
    h5 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .flight-details-table {
    .ant-table-thead {
      white-space: pre-wrap;
    }
    .ant-table-tbody {
      white-space: pre-wrap;
    }
    .ant-pagination {
      display: none;
    }
  }
}
.cancellation-block {
  margin: 15px;
  @include mobile {
    margin: 0;
  }
  .cancellation-remarks {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      width: 100%;
    }
    .cancellation-title {
      display: flex;
      width: 55%;
      justify-content: space-between;
      align-items: center;
      @include mobile {
        width: 100%;
        margin-bottom: 10px;
      }
      h5 {
        width: 50%;
        @include mobile {
          font-size: 15px;
        }
      }
      .flexible-text-area {
        width: 70%;
      }
    }
    .cancel-btn {
      width: 35%;
      text-align: center;
      span {
        font-weight: bold;
      }
    }
  }
}
.passport-details-block {
  .ant-pagination {
    display: none;
  }
}

.fare-details {
  border: 1px solid #000;
  margin: 15px 0;
  ul {
    li {
      border-bottom: 1px solid #000;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        @include mobile {
          font-size: 13px;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.general-text {
  text-align: start;
  h5 {
    font-size: 14px;
  }
}
.colored-text {
  color: red;
}
/******** Payment gateway styles *********/
.paymentgateway-details {
  margin-bottom: 15px;
  .ant-pagination {
    display: none;
  }
}
/*************** Markup *********/
.markup-list {
  text-align: end;
  display: block !important;
  .markup {
    span {
      margin-right: 15px;
    }
    input {
      width: 80px;
    }
  }
}
.confirmed {
  color: rgb(21, 182, 21);
}

.terms {
  margin: 15px 0;
  max-width: 1100px;
  margin: auto;
  // margin-top: 15px;
  .terms-conditions-title {
    padding: 10px 0;
    background-color: #1890ff;
    h5 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .terms-conditions-body {
    border: 1px solid #f0f0f0;
    p {
      text-align: left;
    }
  }
}
.ticket-row {
  @include mobile {
    flex-wrap: wrap-reverse;
  }
}
.flight-ticket-collapse {
  .ant-collapse-borderless {
    background-color: transparent;
  }
}
.collapsed-data {
  .ant-collapse-item {
    .ant-collapse-header {
      width: 107px;
      padding: 0;
      margin: auto;
      .ant-collapse-arrow {
        display: none !important;
      }
    }
  }
}
.result-block {
  @include mobile {
    padding: 60px 0 15px !important;
  }
}
.ticket-options {
  padding: 15px;
  border: 1px solid #d9d9d9;
  margin-bottom: 15px;
  text-align: start;

  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    cursor: pointer;
    .anticon {
      margin-right: 8px;
    }
    a {
      color: rgba(0, 0, 0, 0.85);
      text-decoration: none;
      &:hover {
        color: #1890ff;
      }
    }
    &:hover {
      color: #1890ff;
    }
  }
}
.flight-ticket-titles {
  @include mobile {
    padding: 0 15px;
  }
  h4 {
    margin-bottom: 0;
  }
  span {
    font-size: 15px;
    color: #7d7575;
  }
}
.ticket-full-details {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: auto;
  margin-top: 15px;
  @include mobile {
    padding: 0 15px;
    flex-direction: column;
  }
  .pnr-number,
  .status,
  .ref-no {
    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      span {
        font-weight: normal;
      }
      .booking-status {
        color: green;
      }
    }
  }
}
.view-flight-ticket-btn {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.table-scroll-none {
  td,
  th {
    @include mobile {
      font-size: 13px;
    }
  }
}
/* Dec-16-2020 */
.total-tic {
  font-weight: 700;
}
.flight-tic-line::after {
  content: "";
  border-bottom: 2px solid #e2076a;
  width: 32px;
  margin-top: 3px;
  display: block;
}
.flight-details-line::after {
  content: "";
  border-bottom: 2px solid #e2076a;
  width: 46px;
  margin-top: 7px;
  display: block;
}
.mobile-tic-info {
  display: none;
}
@media screen and (max-width: 480px) {
  .web-tic-info {
    display: none;
  }
  .mobile-tic-info {
    display: block;
  }
  .mobile-tic-info .ant-collapse-header {
    font-weight: 700;
  }
}

/* Dec--19-2020 */

.mobile-tic-info {
  display: none;
}

@media screen and (max-width: 768px) {
  .web-tic-info {
    display: none;
  }

  .mobile-tic-info {
    display: block !important;
  }

  .mobile-tic-info .ant-collapse-header {
    font-weight: 700;
    text-align: left;
  }
}
