// .contact_wrapper {
// background-color: #f5fbf7;
//   padding: 10px;
//   width: 100%;
//   .ant-divider-inner-text {
//     color: #f0802d;
//     font-weight: 600;
//   }
//   .inner_wrapper {
//     padding: 8px;
//     .contact-boxshadow {
//       background-color: #fff;
//       border-radius: 8px;
//       box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
//     }
//     .ant-form {
//       label {
//         color: #05004e;
//         font-weight: 600;
//       }
//       .inputbg,
//       .ant-select-selector {
//         background-color: #f1f2f6 !important;
//       }
//       .phno {
//         .ant-input-group-addon {
//           background-color: transparent;
//         }
//         input {
//           background-color: transparent;
//         }
//       }
//       .btn_wrapper {
//         text-align: center;
//         button {
//           font-size: 16px;
//           font-weight: 500;
//           background-color: #f0802d !important;
//           color: #fff;
//           border: none;
//           margin: 15px 0;
//         }
//       }
//     }
//     .contact_header {
//       text-align: center;
//       margin-bottom: 16px;
//       h2 {
//         font-size: 24px;
//       }
//     }
//     .dateStyle {
//       width: 100%;
//     }
//   }
// }

// .contact_wrapper {
//   // display: flex;
//   // flex-wrap: wrap;
//   // justify-content: space-between;
//   // padding: 20px;
//   // background-color: #f5f5f5;

//   .cont-body {
//     display: flex;
//     // flex-direction: column;
//     // width: 60%; 
//     background-color: #fff;
//     padding: 20px;
//     border-radius: 8px;
//     // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//     .inner_wrapper {
//       .ant-divider-inner-text {
//         color: #f0802d;
//         font-weight: 600;
//         font-family: cursive;
//       }

//       .contact_header {
//         text-align: center;
//         margin-bottom: 20px;
//         color: rgb(240, 128, 45);

//         .common-headings {
//           font-size: 1.8rem;
//           color: rgb(240, 128, 45);
//         }
//       }

//       .contact-boxshadow {
//         // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//         border-radius: 8px;
//         padding: 16px;
//         background-color: white;

//         label {
//           // font-weight: 600;
//           font-family: 'Noto Sans';
//         }

//         .inputbg {
//           border-radius: 6px;
//           border: 1px solid #ddd;
//           padding: 8px;
//           width: 100%;
//         }

//         .dateStyle {
//           width: 100%;
//         }

//         .btn_wrapper {
//           text-align: center;
//           margin-top: 20px;

//           .btn-hover-value-sub {
//             background-color: #1890ff;
//             color: #fff;
//             padding: 5px 100px;
//             letter-spacing: 1px;
//             border-radius: 4px;
//             cursor: pointer;
//             transition: background 0.3s ease;

//             &:hover {
//               background-color: rgb(240, 128, 45);
//               ;
//             }
//           }
//         }

//         .phno .ant-input-group-addon {
//           background-color: #fff;
//         }
//       }
//     }

//     .from-contact-style {
//       width: 60%;
//     }
//   }

//   .cont-body1 {
//     width: 50%; // Right side contact details area
//     display: flex;
//     flex-direction: column;
//     background-color: #fff;
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     height: fit-content;

//     hr {
//       border: 0;
//       height: 1px;
//       background-image:
//         -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0));
//       margin: 20px;
//     }

//     .conthdr {
//       padding: 4px;

//       h4 {
//         font-size: 1.4rem;
//         font-weight: 700;
//         color: rgb(240, 128, 45);
//         margin-bottom: 10px;
//       }

//       p {
//         font-size: 1rem;
//         color: #666;
//         margin-bottom: 20px;
//         // width: 80%;
//       }

//       border-bottom: 2px solid radial-gradient(#000000, #f5f5f5);
//     }

//     .contbod {
//       margin-top: 20px;

//       h4 {
//         font-size: 1.2rem;
//         font-weight: 600;
//         color: rgb(240, 128, 45);
//         ;
//       }

//       h6 {
//         font-size: 1rem;
//         font-weight: 600;
//         color: #555;
//       }

//       p {
//         font-size: 0.9rem;
//         color: #666;
//         line-height: 1.5;
//         width: 60%;
//       }
//     }

//     .contfoot {
//       margin-top: 20px;

//       h6 {
//         font-size: 1rem;
//         font-weight: 600;
//         color: rgb(240, 128, 45);
//       }

//       p {
//         font-size: 0.9rem;
//         color: #666;
//         line-height: 1.5;
//       }
//     }
//   }
// }

// // Modal Styling
// .successModalBox {
//   text-align: center;

//   .sucessModal {
//     .modalIcon {
//       font-size: 3rem;
//       color: green;
//       margin-bottom: 20px;
//     }

//     h6 {
//       font-size: 1.4rem;
//       font-weight: 700;
//       color: #333;
//     }

//     p {
//       font-size: 1rem;
//       color: #666;
//     }
//   }

//   .socialLinks {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 20px;

//     .sharetext {
//       margin-right: 10px;
//       font-size: 1rem;
//       color: #333;
//     }

//     .socialwrap {
//       margin: 0 5px;
//       font-size: 1.5rem;
//       cursor: pointer;

//       a {
//         color: #1890ff;

//         &:hover {
//           color: #40a9ff;
//         }
//       }
//     }
//   }
// }

@import "../../assets/variableStyles.scss";

.contact_wrapper {
  .cont-body {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 0 7%;
    @include mobile{
      margin: 0;
    }
    .inner_wrapper {
      flex: 1 1 100%;
      
      max-width: 600px;
      
      margin: 0 auto;

      /* Centers the form */
      .name-fild {
        display: flex;
        justify-content: space-between;

        @include mobile {
          display: block;
        }
      }

      .ord-feild {
        display: flex;
        justify-content: space-between;

        @include mobile {
          display: block;
        }
      }
    }

    .from-contact-style {
      width: 100%;
      
    }
  }

  .cont-body1 {
    flex: 1 1 100%;
   
    max-width: 500px;
    
    margin: 0 auto;
    
  }

  @media (max-width: 1024px) {
    .cont-body {
      // flex-direction: column;
      /* Stacks content vertically */
      align-items: center;
      /* Centers the content */
    }

    .inner_wrapper {
      width: 100%;
      padding: 10px;
    }

    .cont-body1 {
      width: 100%;
      margin-top: 20px;
     
    }
  }

  @media (max-width: 768px) {
    .cont-body {
      padding: 10px;
      /* Reduces padding for smaller screens */
    }

    .cont-body1 {
      padding: 10px;
      box-shadow: none;
      /* Simplifies the layout for small screens */
    }

    .contfoot p,
    .contbod p,
    .conthdr p {
      
      width: 100%;
      /* Ensures text spans full width */
    }
  }

  @media (max-width: 480px) {
    .cont-body {
      padding: 5px;
      flex-direction: column;
      
    }

    .inner_wrapper,
    .cont-body1 {
      padding: 10px;
      max-width: 100%;
      
    }

    .btn_wrapper .btn-hover-value-sub {
      padding: 10px;
      
      width: 100%;
      
    }

    .socialLinks {
      flex-direction: column;
     
      gap: 10px;
     
    }

    .socialwrap a {
      font-size: 1.2rem;
      
    }
  }
}