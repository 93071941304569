.bus-filters-wrapper {
  .busfilterinput {
    position: sticky;
    top: 0;
    z-index: 6;
    margin-bottom: 10px;
  }
  .ant-card-body {
    padding: 0;
    .ant-collapse-borderless {
      background-color: transparent;
    }
    .bus-results {
      padding: 12px 16px;
      border-bottom: 1px solid #d9d9d9;
      p {
        margin: 0;
        font-weight: 600;
      }
    }
    .bus-filter-header {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d9d9d9;
      p {
        margin: 0;
      }
      .fil-text {
        font-weight: 600;
      }
      .clear-text {
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .ant-collapse-header {
      font-size: 14px;
      font-weight: 600;
      .ant-collapse-extra {
        font-size: 12px;
      }
    }
    .ant-collapse-content {
      .ant-checkbox-wrapper {
        font-size: 13px;
        font-weight: 500;
        color: #05004e;
      }
      .ant-slider-handle,
      .ant-slider-track {
        background-color: #f0802d;
        border: none;
      }
      .ant-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -6px;
      }
      .input-range__slider,
      .input-range__track--active {
        background-color: #f0802d;
        border: none;
      }
    }
    .locations {
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        span {
          &:nth-child(2) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .scroll {
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f7f7f7;
        border-radius: 16px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #d8d8d8;
        border-radius: 16px;
      }
      ::-webkit-scrollbar-button {
        display: none;
      }
      .ant-collapse-content {
        max-height: 220px;
        overflow-y: scroll;
        margin-right: 5px;
      }
    }

    .slider-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
