.retrive-btn{
    background: #1890ff;
    display: block;
    border: none;
    cursor: pointer;
    color: #fff;
    text-align: center;
    padding: 6px 24px!important;
    
    font-weight: 400!important;
}
.cancel-modal{
    width: 900px !important;
}
.refence-tic .ant-descriptions-item-label {
    font-weight: bold;
}