@import "../assets/variableStyles.scss";
@import "./Deposits/Deposits.scss";
$primary: #f0802d;
$secondry: #05004e;

#admin {


  h5,
  h6 {
    color: $secondry !important;
  }

  .primaryBg {
    background: $primary;
  }

  .primaryColor,
  .travelDateContent span {
    color: $primary;
  }

  .secondryBg {
    background: $secondry;
  }

  .secondryColor {
    color: $secondry;
  }

  .name-admin-header {
    visibility: visible;
    border: 2px solid transparent;
    margin-top: -1.5%;

    @include mobile {
      visibility: hidden;
    }
  }

  .check-balance {
    border: 2px solid transparent;


  }



  .ant-modal-header,
  .ant-radio-button-wrapper-checked {
    background-color: $primary !important;
    border-color: $primary;
  }

  .ant-btn-danger {
    color: white !important;
  }

  .ant-table-thead tr th {
    background: #ffd483 !important;
    color: #000 !important;
    font-weight: 600;
    font-style: calibri;
    font-size: 13px;
  }

  .ant-table-content td,
  .ant-picker-calendar-date-value {
    color: $secondry !important;
  }

  .ant-upload .anticon {
    color: $secondry;
  }

  .ant-btn {

    // background-color: $primary !important;
    // border-color: $primary !important;
    // color: #fff;
    &:hover {
      background: $secondry !important;
      background-color: $secondry !important;
    }
  }

  .logo {
    width: auto !important;
    overflow: hidden;
    padding: 8px;
    background: #ffffff;
  }

  .logo1 {
    width: auto !important;
    position: absolute;
    z-index: 10;
    left: 50px;
    visibility: visible;

    @include mobile {
      visibility: hidden;
    }
  }

  .logo2 {
    width: auto !important;
    position: absolute;
    z-index: 10;
    left: 50px;
    visibility: hidden;

    @include mobile {
      visibility: visible;
    }
  }

  .ant-layout-sider {
    background: $secondry;
  }

  aside ul {
    background: white !important;
    color: #000;
  }

  .ant-menu-item-selected {
    border-left: 7px solid orange;
    background: #f5d9ba !important;
  }


  .ant-menu-item-selected .hover-css {
    color: black;
  }

  .ant-menu-submenu :hover {
    color: rgb(255, 145, 0);
    background-color: #f5d9ba;
  }



  // .side-bar-scss {
  //   .ant-menu-submenu {
  //     border: 2px solid black;
  //   }
  // }

  // .hover-css :hover{
  //   background-color: black;
  // }

  //  .side-bar-scss {
  //    .ant-menu-submenu {
  //      .ant-menu-item {
  //        border: 2px solid black;
  //       }
  //    }
  //  }



  // .ant-menu-item :hover .hover-css{
  //   color: red;
  // }

  .ant-collapse-header {
    color: white;
    background-color: $primary;
    font-weight: 600;
    font-style: Calibri;

    .anticon-caret-right {
      font-size: 12px;
      font-style: Calibri;

    }
  }

  .ant-form-item-labelp labelp,
  .upload-logo p,
  .ant-upload .ant-upload-text {
    color: $primary !important;
    font-weight: 600;
    font-style: Calibri;

  }

  header {
    z-index: 9;
  }

  .adminFooter {
    border-top: 1px solid white;
    text-align: center;
    width: 97.5%;
    margin: auto;
    padding-top: 1%;
    padding-bottom: 0.5%;
  }

  .anticon-upload,
  .ant-radio-button-wrapper-checked,
  .ant-picker-content tr th {
    color: #fff !important;
    font-weight: 600;
    font-style: Calibri;

  }

  .ant-picker-content tr th {
    background-color: $primary;
    border: 1px solid #fff;
    padding: 5px 12px;
  }

  .ant-picker-calendar-date-today {
    border-color: $secondry;
  }

  .eyewrapper {
    text-align: center;
    color: green;
  }

  .eyered {
    color: red;
  }

  .profile_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .profile-details {
    .profile-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #ccc;
      margin: 10px 0px;
    }
  }

  .profile-update {
    .member-since {
      display: flex;
      justify-content: space-between;
      // color: #1890ff;
      font-weight: 600;
      font-style: Calibri;

    }

    .update-btn {
      button {
        border: none;
        width: 100%;
      }
    }
  }
}

.ant-table-thead {
  white-space: nowrap;
}

.ant-table-tbody {
  white-space: nowrap;
}

.manage-markup-section {
  border: 2px solid transparent;
  border-radius: 25px;
  max-width: 100%;
  margin: auto;
}

.outer-background-all {
  background-color: #fafafa;
}

.container-fluid {
  border: 2px solid #fafafa;
  background-color: #fafafa;
}

.outer-background-all .manage-markup-section {
  background-color: #fafafa;
  width: 100%;
  padding: 0%;
  margin-top: 1%;
}

.card-bt-gap {
  border: 2px solid transparent;
  width: 100%;
  border-radius: 25px;
  

}
.ant-form-item {
  margin-bottom: 0px !important;
}

.head-layout-check {
  .ant-collapse {
    border: 2px solid transparent;
    padding-left: 1%;
  }
}

.outer-background-all {
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}




.ant-layout-content {
  -ms-flex: auto;
  flex: auto;
  min-height: 0;
  background-color: #fafafa;
}

@include mobile {
  .manage-markup-section {
    margin-top: 18px;
    padding: 0 !important;
  }

  .ant-card-body {
    padding: 15px;
  }

  .card-add-heading {
    flex-wrap: wrap;

    .rows-count {
      max-width: 100%;
      width: 100%;
    }

    h5 {
      margin-bottom: 0;
      font-size: 15px;
      font-style: Calibri;

    }

    .add-icon {
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    }
  }

  .ant-table-thead {
    tr {
      th {
        width: 100px !important;
      }
    }
  }

  .ant-modal-content {
    width: 90%;
    margin: auto;
  }
}

.site-layout-background1 {
  background: #fff;

  @include mobile {
    width: 100%;
  }
}

.headerColor {
  border: 2px solid transparent;

  .anticon {
    color: $secondry;
  }
}


.trigger {
  font-size: 12px;
  font-style: Calibri;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

@include mobile {
  // .promo-modal-header {
  //     overflow-x: hidden;
  // }
}

.ant-modal-title {
  color: #fff !important;
}

.seperation-line {
  border-top: solid 1px #ccc;
  padding-top: 20px;
}

.card-gap {
  margin-top: 20px;

  .ant-card-head {
    background: #1890ff !important;
    color: #fff !important;
  }

  .flight-depart-details {
    .flight-details {
      border-top: solid 1px #ccc;
      padding-top: 30px;
      margin-top: 20px;

      .flight-title {
        text-align: center;
      }
    }
  }
}

.ant-menu {
  .fa {
    margin-right: 10px !important;
  }
}

#admin {
  .ant-menu {
    .fa {
      margin-right: 10px !important;
    }
  }

  .ant-layout-sider-collapsed {
    @include mobile {
      // min-height: 100vh;
      flex: 0 0 60px !important;
      width: 60px !important;
      min-width: 60px !important;
      max-width: 60px !important;
      display: block !important;
    }
  }

  .ant-menu-inline-collapsed {
    @include mobile {
      width: 60px !important;
      text-align: center;
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 32px !important;
    line-height: 32px;
    color: #000;
    padding-left: 30px !important;

    @include mobile {
      margin: 0 !important;
      padding: 0 16px !important;
    }
  }

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 32px !important;
    line-height: 28px;
    color: #000;

    @include mobile {
      padding: 0 16px !important;
    }
  }

  .ant-layout-header {
    height: 70px !important;
    line-height: 70px !important;
    padding: 0px 13px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;

    @include mobile {
      padding: 0px !important;
    }
  }
}

.bookoffline-submit {
  text-align: right;

  button {
    background: #1890ff;
    color: #fff;
    font-size: 20px;
    padding: 20px 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-flex {
  display: flex;
}

/* settings */

.gst-section {
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.left-details {
  @include mobile {
    order: 1;
    margin-top: 25px;
  }
}

/* API logs */
.copy-btn {
  text-align: right;

  button {
    background: #1890ff;
    color: #fff;
    border: none;
    font-size: 12px;
    height: 27px;

    &:hover {
      background-color: #fff;
      border: solid 1px #1890ff;
    }
  }
}

.action-images {
  span.ant-input-affix-wrapper {
    width: auto;
  }

  img {
    cursor: pointer;
  }
}

.admin-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.view-dn {
  position: relative;
  top: -51px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}

.all-view-h5 {
  position: relative;
  top: -20px;
}

.view-dn-new {
  position: relative;
  top: -42px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}

.all-view-h5-new {
  position: relative;
  top: -20px;
}

.inner-drop-down {
  padding-left: 50px !important;
}

/*09-09-2020--changes--*/
.admin-preference {
  width: 900px !important;
}

.ant-table-content {
  overflow: scroll;
  font-size: 13px;
  font-family: Calibri;
}

.logo-view {
  width: 74%;
}

.ant-table-pagination-right {
  -ms-flex-pack: end;
  justify-content: center;
}

#admin .ant-menu-item a {
  color: #000;
}

#admin .ant-layout-sider-children {
  background: white;
  // border-right: 1px solid #efeded;
}

#admin .anticon-menu-fold {
  position: absolute;
  z-index: 10;
  left: 0px;
}

#admin .anticon-menu-unfold {
  position: absolute;
  z-index: 10;
  left: 30px;
}

@media screen and (max-width: 768px) {
  .all-books-btn {
    display: block;
    margin-top: -20px;
  }

  #admin .anticon-menu-fold {
    left: 0px !important;
  }

  #admin .anticon-menu-unfold {
    left: 46px !important;
  }

  .bus-bc-tic {
    margin-top: 8px;
  }

  .logout-admin-1 {
    margin-top: -20px;
  }

  .bus-bc-tic {
    display: none !important;
  }
}

.book-admin-all {
  margin-bottom: 0px;
  color: #000;
}

#admin .ant-menu-sub {
  background-color: white !important;
  color: #000;
}

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: white !important;
}

.logout-admin-1 {
  margin-bottom: 0px;
}

.logout-admin-1 .anticon-logout {
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
}

.wrap-block-sec {
  display: inline-flex;
  margin-left: auto;
}

.bus-bc-tic {
  padding-top: 20px;
}

.all-books-btn {
  margin-bottom: 0px;
  color: #05004e !important;
  font-size: 14px;
  font-weight: 600;
}

.all-books-btn:hover {
  color: #f0802d !important;
  // border-bottom: 1px solid #f0802d;
}

.second-log-out {
  border: 2px solid transparent;
  display: flex;

  @include mobile {
    height: 100%;
  }
}

.user-log-menu {
  display: block;
  margin-left: auto;
}

.input-style-enter {
  .ant-input {
    border-radius: 25px;
  }
}



.second-log-out .ant-btn {
  background: transparent !important;
  border: 0px !important;
}

.bus-tic-more .ant-form-item {
  margin-bottom: 0px;
}

.all-books-btn .fa {
  font-size: 24px;
}

.all-books-btn {
  padding-top: 8px;
}

.logout-admin-1 {
  padding-top: 4px;
}

.ant-tooltip {
  top: 50px !important;
}

@media screen and (max-width: 768px) {
  #admin .ant-layout-header {
    height: 45px !important;
  }

  .member-ship-sec {
    width: 100% !important;
    margin-bottom: 8px;
  }
}

.bci-panel-admin-parts .ant-form-item {
  margin-bottom: 3px;
}

.site-admin-btn-11 {
  background-color: #f0802d !important;
  border-color: #f0802d !important;
  color: #fff !important;
  margin-right: 0 !important;
  width: 100px !important;
}

.quick-help-table {
  margin-top: 26px;
}

.balance-dat {
  border: 2px solid transparent;
  position: absolute;
  top: 4px;
  right: 27%;
  padding-right: 5%;
  visibility: visible;

  @include mobile {
    visibility: hidden;
  }
}

.ant-layout-footer .footer-row {
  padding: 2px 50px;
}



//new


.conTab {
  padding: 20px;
  // background-color: #f9f9f9;
}

.Summstatement-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .ant-card-head {
    background: antiquewhite;
  }
  .ant-card-body{
    padding: 0;
  }
}

.card-tit-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.card-tit-cont1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 18px;
}

.title-main-card {
  display: flex;
  flex-direction: column;
}

.headr-p {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.desc-p {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.rem-amt-p {
  font-size: 16px;
  font-weight: bold;
  color: #27ae60;
}

.cus-tbl-ledreport {
  width: 100%;
  border-collapse: collapse;
  // margin-top: 8px;
}

.cus-tbl-ledreport tbody tr {
  border-bottom: 1px solid #eaeaea;
}

.labelp-tb,
.labelp,
.value-p {
  padding: 10px;
  font-size: 14px;
  color: #333;
}

.labelp-tb,
.labelp {
  width: 70%;
}

.value-p {
  width: 30%;
  text-align: right;
  font-weight: bold;
  color: #444;
}

.title-main {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .conTab {
    padding: 10px;
  }

  .Summstatement-card {
    margin-bottom: 16px;
  }

  .rem-amt-p {
    font-size: 14px;
  }

  .headr-p {
    font-size: 14px;
  }

  .desc-p {
    font-size: 10px;
  }

  .cus-tbl-ledreport {
    font-size: 12px;
  }

  .labelp-tb,
  .labelp,
  .value-p {
    padding: 8px;
  }
}