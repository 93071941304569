.cancellation-wrapper {
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p:first-child {
        text-align: left;
      }
      .text-align {
        text-align: center;
      }
    }
  }
}

.flightCancelDetials {
  display: flex;
}
