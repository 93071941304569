@import "../../assets/variableStyles.scss";

.reset-button-fst {
  justify-content: flex-start !important;
  float: left;
}

.email-from-book-reports {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17%;
  height: 180px;
  width: 180px;
}

.Modalstyle {
  width: 1000px !important;
}

.main-tab-buttons1 {
  margin-top: -4.5%;
  @include mobile{
    margin-top: -14%;
  }
}

.main-tab-buttons1 {
  .ant-tabs-nav-list {
    margin-left: 86%;
    @include mobile{
      margin-left:60%;
    }
  }
}
.card-add-heading{
  .card-bt-gap{
    margin-bottom: -18px;
    @include mobile{
      margin-bottom: -0px;
    }
  }
}

.vocher3div .vocher31div {
  margin: 5px 0;
  float: left;
  border-top: 1px solid #f5e79c;
  width: 100%;
  background: #fdf8e4;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
}

.fix-height {
  height: 205px;
}

.tableheader {
  text-align: center;
  padding: 15px !important;
}

.barcode {
  padding: 0;
  margin-left: -10px;
  width: 50px;
  height: 50px;
  float: left;
}

.mt15 {
  margin-top: 15px !important;
}

.qrscan {
  font-size: 13px;
  color: #2d3e52;
}

.new-voucher .costm-detail {
  overflow: hidden;
  border-radius: 7px;
  border: solid 1px #cddee5;
}

.new-voucher .txt-travel {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.img {
  object-fit: cover;
  vertical-align: middle;
}

.newvouchico {
  background: url(https://d1vqfl8cu8qgdj.cloudfront.net/assets/img/voucherimg.png) no-repeat;
}

.binoculars {
  background-position: -70px -6px !important;
}

h3,
.h3 {
  font-size: 15px;
}

.reficon {
  width: 60px;
  height: 35px;
  margin: 0 auto 10px;
}

.vocherdivi {
  margin-bottom: 20px;
}

.lefttable {
  background-color: #e94057;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
}

.cancelButton {
  color: #f5f5f5;
  background: #e94057;
}

.OkButton {
  background: #5b2bdf;
  color: #f5f5f5;
}

.lefttabl {
  text-align: center;
  background-color: #e94057;
  color: white;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
}

.centeralign {
  text-align: center;
  padding: 10px;
}

.tourdetails {
  margin-top: 10px;
  color: white;
  background-color: #e94057;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.border {
  border: 1px solid #dee2e6;
  margin-top: 10px;
  border-radius: 4px;
}

.borde {
  border: 1px solid #dee2e6;
  margin: 10px;
  border-radius: 4px;
}

.Vocher2div {
  margin-bottom: 16px;
  background: #18179d;
  color: #f5f5f5;
  border: 1px solid #b3daa1;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
  border-radius: 4px;
}

.vocher-table {
  margin-bottom: 10px;
}

.Vocher1div {
  text-align: center;
  margin-bottom: 16px;
  color: #f5f5f5;
  background-color: #e94057;
  padding: 10px;
  font-size: 20px;
  border-radius: 4px;
}

.imageheight {
  margin: 10px;
}

.li-vocher li {
  list-style-type: disc;
}

.li-vocher {
  padding-left: 20px;
}

.card-style {
  margin: auto !important;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
  margin: 25px 0px;
  border-radius: 10px;

  @include mobile {
    width: 350px;
  }
}