.check {
    border-bottom: 1px solid rgb(216, 213, 213);
    width: 99%;
    margin: auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    margin-top: 0%;
}

.check1 {
    border: 2px solid transparent;
    width: 26%;
}

.check2 {
    border: 2px solid transparent;
    width: 18%;
}

.check3 {
    border: 2px solid transparent;
    width: 25%;
}

.check4 {
    border: 2px solid transparent;
    width: 10%;
}

.check5 {
    border: 2px solid transparent;
    width: 20%;
}


h1 {
    border: 2px solid transparent;
    color: black;
    font-size: 15px;
    font-style: calibari;

}

.button-boarding {
    border: 2px solid transparent;
    color: black;
    padding: 0%;
    background-color: transparent;
}

.button-boarding {
    .button-color-all {
        color: black;
    }
}

.popup-main-box {
    border: 2px solid transparent;
    width: 100%;
}

.popup-main-box1 {
    border: 2px solid transparent;
    width: 100%;
}

.popup-main-box2 {
    border: 2px solid transparent;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
}
.popup-main-box4 {
    border: 1px solid rgb(172, 172, 172);
    width: 19.9%;
    background-color: orange;
    text-align: center;
    margin-top: -1%;
    color: black;
}
.destination-check1{
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    margin: auto;
border-bottom: 1px solid rgb(202, 202, 202);
 }
.destination-check{
    border: 1px solid rgb(202, 202, 202);
    background-color: rgb(247, 196, 103);
    margin-bottom: 1%;
    width: 25%;
    border-radius: 25px;
    text-align: center;
}
.popup-main-box3 {
    border: 1px solid rgb(172, 172, 172);
    width: 20%;
    text-align: center;
    margin-top: -1%;
    color: black;
}

.modal-dialog {
    border: 2px solid transparent;
    max-width: 900px;
}