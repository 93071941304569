.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full height of the viewport */
  // background: radial-gradient(rgb(47, 47, 47),transparent)/* Gradient background */
}
.payment-info-topUp {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  background-color: #f9f9f9;
  box-shadow: 2px 3px 8px grey;
}

.payment-det-Insta_TopUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payment-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.payment-details {
  width: 100%;
}

.payment-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  font-weight: 600;
  color: #333;
}

.value {
  font-weight: 400;
  color: #666;
  margin-left: 10px;
  text-align: right;
  flex: 1;
}
