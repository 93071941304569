.print-ticket-btn{
    display: block !important;
    text-align: right;
    margin-bottom: 30px;
}
.printmail{
    padding-top: 3px;
}
.print-ticket-btn .all-print-btn{
   
    padding-top: 7px;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #007bff;
    font-weight: 600;
    line-height: 15px;
    display: inline-flex;
}

@media screen and (max-width: 767px){
.print-ticket-btn{
    text-align: start;
    display: flex;
    justify-content: space-between;
}
.print-ticket-btn .all-print-btn{
    margin-right: 1px;
}

}

.space-help{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
}

.invoice-body-first-line-1 {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    flex-wrap: wrap;
}
.invoice-body-first-line-1 .invoice-from-to-details-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.invoice-body-first-line-1 .invoice-from-to-details-1 .from-to-names-1 {
    margin-right: 20px;
}
.invoice-body-first-line-1 .invoice-from-to-details-1 .invoice-date-time-1 {
    font-size: 16px;
}
.invoice-body-first-line-1 .invoice-from-to-details-1 .from-to-names-1 ul {
    display: flex;
    margin-bottom: 0;
}
.invoice-body-first-line-1 .invoice-from-to-details-1 .from-to-names-1 ul li {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 900;
}
/* invoice--second--line */

.invoice-second-line-1 {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
}
.invoice-second-line-1 ul {
    display: flex;
    margin-bottom: 0;
}
.invoice-second-line-1 ul li {
    width: 25%;
}
.invoice-second-line-1 ul li div:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}
 .invoice-second-line-1 ul li div:nth-child(2) {
    color: #757575;
    font-size: 11px;
}

@media screen and (max-width: 767px){
    .invoice-second-line-1 ul li {
        width: 100%;
    }
    .invoice-second-line-1 ul{
        display: block !important;
    }
}

/* third--line */
.invoice-third-line-3 {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
}
.invoice-third-line-3 ul {
    display: flex;
    
    align-items: center;
}
.invoice-third-line-3 ul li {
    width: 25%;
}
.invoice-third-line-3 ul li div:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}
.invoice-third-line-3 ul li div:nth-child(2) {
    color: #757575;
    font-size: 11px;
}
.invoice-third-line-3 ul li div:nth-child(3) {
    color: #757575;
    font-size: 11px;
}

@media screen and (max-width: 767px){
    .invoice-third-line-3 ul li {
        width: 100%;
    }
    .invoice-third-line-3 ul{
        display: block !important;
    }
}

/* fouth--line--css */
.invoice-fourth-line-4 {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
}
.invoice-fourth-line-4 ul {
    display: flex;
    margin-bottom: 0;
    align-items: center;
}
.invoice-fourth-line-4 ul li {
    width: 25%;
}
.invoice-fourth-line-4 ul li div:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

@media screen and (max-width: 767px){
    .invoice-fourth-line-4 ul li {
        width: 100%;
    }
    .invoice-fourth-line-4 ul{
        display: block !important;
    }
}

/* fith--line--csss*/

.invoice-fifth-line-5 {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
}
.invoice-fifth-line-5 ul {
    display: flex;
    margin-bottom: 0;
    align-items: center;
}
.invoice-fifth-line-5 ul li {
    width: 50%;
}
.invoice-fifth-line-5 ul li div:nth-child(1) {
    color: #757575;
    font-size: 11px;
}
.invoice-fifth-line-5 ul li div:nth-child(2) {
    font-size: 14px;
}

/* sixth--line */

.invoice-note-6 .note-indication-6 {
    font-size: 11px;
}
.invoice-note-6 .total-fare-6 {
    text-align: right;
    padding: 0;
}
.invoice-note-6 .total-fare-6 .invoice-price-6 {
    font-size: 14px;
    font-weight: 600;
}
.invoice-note-6 .total-fare-6 .invoice-tax-info-6 {
    color: #757575;
    font-size: 11px;
}
@media screen and (max-width: 767px){
.invoice-note-6 .total-fare-6 {
    text-align: left;
    padding: 0;
}
}