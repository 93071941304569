@import "../src/components/HomeCarousel/Slick_CarouselStyle/slickTheme";
@import "../src/components/HomeCarousel/Slick_CarouselStyle/slick";
@import "../src/components/flight-search/FlightSearch.scss";
@import "assets/variableStyles.scss";
.ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: 6px 8px !important;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: 4px 8px !important;
}
.ant-table-column-sorters {
  padding: 0 !important;
}

.cursor_pointer {
  cursor: pointer;
}
.main_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  .main_content {
    flex: 1 0 auto;
    // background-color: #1b1b1b;
  }
  .footer_content {
    flex-shrink: 0;
  }
}
.flight-checkout {
  .flight-timings {
    width: 100%;
  }
}
.empty_seat {
  background-color: white !important;
}
.results_tab {
  overflow: unset !important;
}

.tabs-container .ant-tabs-nav {
  position: sticky !important;
  top: 0;
  background-color: white;
  z-index: 999;
}
.filter-section-flight,
.filter-section-bus {
  .ant-card-body {
    background-color: #f7f7f7;
  }
  .fl-tops-bar {
    z-index: 1000;
  }
}
.filter-section-bus {
  .ant-card-body {
    z-index: 1000;
  }
}
.cms_pagelisting ul li {
  list-style-type: disc !important;
}
.cms_pagelisting ol li {
  list-style-type: upper-alpha !important;
}

.cms-table {
  .ant-table-cell div {
    max-height: 66px;
    overflow: hidden;
  }
}

.grand_total_card_row {
  padding: 8px 16px;
  font-size: 16px;
}
.ticket_rules h2 {
  text-align: center;
}
.roundtripSeprator {
  text-align: center;
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}

.returnTitle {
  ul {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    @include mobile {
      display: block;
    }
    li {
      font-weight: 600;
    }
  }
}

.locTooltip {
  max-width: 240px !important;
  z-index: 99999 !important;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      font-size: 12px;
      padding: 5px 8px;
    }
  }
}
