@import "../../assets/variableStyles.scss";
.footer-block {
  .footer-container {
    max-width: 1200px;
    margin: auto;
    padding: 50px 15px;

    .ongo,
    .in-ofc,
    .help-btn {
      p {
        font-size: 18px;
        font-weight: bold;
        color: #05004e;
      }
      ul {
        li {
          color: #425486 !important;
          font-size: 16px !important;
          line-height: 24px;
          font-style: Calibri;
          margin-bottom: 10px;
        }
      }
    }
    .follow-block {
      p {
        font-size: 18px;
        font-weight: bold;
        color: #05004e;
      }
      .socialIcons {
        display: flex;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #05004e;
          border-radius: 50%;
          margin-right: 10px;
          height: 25px;
          width: 25px;
          cursor: pointer;
          &:hover {
            background-color: #c7436b;
          }
          i {
            color: #fff;
          }
        }
      }
    }
    .comAddr {
      margin-top: 1px;
      li {
        font-size: 16px;
        color: #c7436b;
        font-weight: 700;
        margin-bottom: 1px;
      }
    }
  }
}
