@import "../../assets/variableStyles.scss";
.global_search_panel {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 1050px;
  width: 100%;
  top: 20%;
  margin-top: 19px;
  @include mobile {
    position: static;
    transform: none;
    margin-top: 0;
  }
  h1 {
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    color: white;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 35px;
    @media (max-width:768px) {
      font-size:16px;
      // margin-top: 50px;
      margin-bottom: 0;
      background-color: #002172;
      padding: 25px;
    }
  }
  .search-wrapper {
    background-color: #071624bf;
    padding: 4px;
    border-radius: 5px;
    @include mobile {
      background-color: #002172;
      border-radius: 0;
    }
    .ant-tabs {
      overflow: visible;
    }
    
    .ant-tabs-bar {
      margin: 0;
      border-bottom: none;
    }
    // .nav-tabs {
    //   border-bottom: transparent;
    //   @include mobile {
    //     justify-content: space-between;
    //   }
    //   .nav-link {
    //     color: #fff;
    //     font-size: 14px;
    //     font-weight: 600;
    //     padding: 0.5rem 1rem 0;
    //     @include mobile {
    //       border: 1px solid #0775e2;
    //     }
    //     i {
    //       display: block;
    //       text-align: center;
    //     }
    //     &:hover {
    //       border-color: transparent;
    //       @include mobile {
    //       border: 1px solid #0775e2;
    //       }
    //     }
    //   }
    //   .nav-link.active {
    //     background-color: transparent;
    //     border-color: transparent;
    //     color: #00d2e8;
    //     position: relative;
    //     @include mobile {
    //       color: #fff;
    //       background-color: #0775e2;
    //       border: 1px solid #0775e2;
    //     }
    //     &:after {
    //       content: "";
    //       height: 0px;
    //       width: 0px;
    //       position: absolute;
    //       border-bottom: 5px solid #fff;
    //       border-top: 5px solid transparent;
    //       border-right: 5px solid transparent;
    //       border-left: 5px solid transparent;
    //       left: 50%;
    //       top: 44px;
    //       transform: translate(-50%,0%);
    //       @include mobile {
    //         content: none;
    //       }
    //     } 
    //     // &:before {
    //     //   content:'\f072';
    //     //   display: block;
    //     //   text-align: center;
    //     // }
    //   }
    // }
    .ant-tabs-tab {
      color: #fff;
      text-align: center;
      i {
        display: block;
      }
    }
    .ant-tabs-tab-active {
      color: #1890ff;
    }
    .ant-tabs-tabpane {
      background-color: #fff;
      border-radius: 5px;
    }
    .ant-tabs-nav {
      margin-left: 8px;
    }
  }
}
