@import "../../../assets/variableStyles.scss";
.trip-details-block {
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 25px;
  position: sticky;
  top: 225px;
}
.gstdetails,
.pricedetails {
  background-color: #fff !important;
  padding: 0.75rem;
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    color: #1c2b39;
    font-size: 14px;
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      text-decoration: none;
    }
    i {
      margin-right: 5px;
    }
  }
}

.busDetails_wrapper .card_wrapper .points_wrapper .fa {
  border: 2px solid transparent;
  margin-right: 5%;
  margin-bottom: 5%;
}
.cards-body {
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 3px 15px;
  span {
    &:nth-child(1) {
      margin-left: 20px;
    }
  }
}
.total-fare {
  padding: 15px;
  .price-block {
    display: flex;
    justify-content: space-between;
    .total-text {
      font-size: 24px;
    }
    .price {
      font-size: 24px;
      span {
        font-weight: 600;
      }
    }
  }
  .book-facilities {
    ul {
      margin-bottom: 0;
      li {
        font-size: 12px;
        color: #1da023;
        span {
          border-bottom: 1px dotted #1da023;
        }
        a {
          color: #969dac;
          border-bottom: 1px dotted #969dac;
        }
      }
    }
  }
}

.buscheckout_bg {
  background-color: #eaebee;
  .buscheckout_wrapper {
    // max-width: 1200px;
    padding: 10px;
    margin: 0 auto;
    .ant-card {
      border-radius: 8px;
    }
    .paxinfo_wrapper {
      margin-bottom: 5px;
    }
    .paxinfo_wrapper{
      .ant-card-body {
        padding: 4px;
    }
    }
    .paxcontact_wrapper{
      .ant-card-body {
        padding: 4px;
    }
    }
    .check-last{
      .ant-card-body {
        padding: 4px;
    }
    }
    .right_wrapper{
      .ant-card-body {
        padding: 4px;
    }
    }
    .title {
      font-size: 12px;
      font-weight: 600;
    }
    .ant-form {
      label {
        color: #05004e;
        font-weight: 600;
      }
      .inputbg,
      .ant-select-selector {
        background-color: #f1f2f6 !important;
      }
      .phno {
        .ant-input-group-addon {
          background-color: transparent;
        }
        input {
          background-color: transparent;
        }
      }
      .btn_wrapper button {
        height: auto;
        padding: 5px 15px;
        font-size: 15px;
        font-weight: 600;
        background-color: #f0802d !important;
        color: #fff;
        border: none;
        border-radius: 8px;
      }
    }
  }
}

.offers-modal {
  .coupon-card {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    .image-wrapper {
      width: 100%;
      height: 120px;
      overflow: hidden;
      img {
        max-width: 100%;
        display: block;
      }
    }
    .coupon-content {
      border: solid 1px #ccc;
      padding: 10px;
      border-radius: 0 0 8px 8px;
      .coupon-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .codes {
          flex: 1;
          p {
            font-size: 12px;
            margin-bottom: 0px;
          }
          h2 {
            font-size: 20px;
            margin-bottom: 0px;
            @include mobile {
              font-size: 16px;
            }
          }
        }
        .copy-code {
          cursor: pointer;
          padding: 5px 10px;
          background-color: #f0802d;
          color: #fff;
          border-radius: 2px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.busCountdown .ant-statistic-content-value {
  color: #f0802d !important;
}
// .busname{
//   font-size: 12px;
//   font-weight: 500;
//   color: #0775e2;
// }
// .fromto{
//   font-size: 12px;
//   font-weight: 600;
// }
 