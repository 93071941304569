@import "../../assets/variableStyles.scss";

.flighttic-info-d1 {
  position: relative;
  //height: 150px;
}

.tic-gredient-f {
  background-image: linear-gradient(to right, rgb(255, 176, 86), rgb(255, 232, 189));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
}

.tic-city-name-d2 {
  .fa-long-arrow-right {
    border: 2PX SOLID transparent;
    padding-left: 2%;
    padding-right: 2%;
  }
}

.tic-city-name-d2 {
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding-top: 40px;
}

.city-from-d2 {
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 7px;
}

.city-f3 p {
  margin-bottom: 0px;
  font-size: 13px;
  font-style: calibri;
  color: rgb(255, 123, 0);
  font-weight: 800;
  padding: 0px 14px 0px 0px;
}

.card-tic-f3 {
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;

  @include mobile {
    border: 2px solid transparent;
  }
}

.flight-ticket-collapse {
  .ant-card-body {
    border: 2px solid transparent;

    @include mobile {
      padding: 5px;
    }
  }
}


.city-f3 span {
  color: #000000;
  font-size: 13px;
}

.last-date-d3 {
  padding-top: 0px;
  padding-left: 17px;
}

.card-tic-f3 .ant-card-body {
  padding: 0px !important;
}

.row-top-f4 {
  padding-top: 0px;
}

.cnfrom-f3 {
  color: #26b265 !important;
}

.city-f4 p {
  margin-bottom: -7px;
  font-size: 13px;
  font-style: calibri;
  font-weight: bold;
}

.city-f4 span {
  color: #000000;
  font-size: 13px;
  font-style: calibri;
}

.card-tic-f1 {
  border: 1px solid #d3d3d3;
  margin-top: 6px;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;
}

.card-tic-f1 .ant-card-body {
  padding: 4px !important;
}

.city-tt-f4 p {
  margin-bottom: -4px;
  font-size: 14px;
  font-weight: 500;
  font-style: calibri;
}

.city-tt-f4 span {
  color: #000000;
  font-size: 13px;
}

.logo-f3-text {
  display: flex;
}

.sg-logo-flight {
  display: block;
  padding-left: 4px;
}

.info-flight-t22 {
  padding-bottom: 10px;
}

.city-tt-f5 p {
  margin-bottom: -4px;
  font-size: 14px;
  font-style: calibri;
  color: #000;
}

.city-tt-f5 span {
  color: #000000;
  font-size: 14px;
}

.city-from-d3 {
  display: flex;
  justify-content: end;
}

.city-f55 p {
  margin-bottom: -7px;
  font-size: 18px;
  font-style: calibri;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
}

.card-tic-f55 {
  border: 1px solid #dedede;
}

.city-f55 span {
  color: #000000;
  font-size: 13px;
  font-style: calibri;
}

.dotted-line-flight {
  border: 1px solid rgb(255, 123, 0);
  border-style: dotted;
  width: 194px;
  margin-top: 14px;
  margin-bottom: 8px !important;
}

.city-f55 {
  text-align: center;
}

.baggagge-f4 {
  display: flex;
}

.baggagge-icon-4 {
  background: #f2f2f2;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  text-align: center;
}

.baggagge-icon-4 .fa {
  text-align: center;
  line-height: 28px;
  font-size: 20px;
  font-style: calibri;
}

.info-line-bag {
  padding-left: 10px;
}

.baggagge-bottom-sec {
  padding-top: 25px;
}

.allowance-baggage {
  padding-left: 10px;
}

.baggagge-icon-5 .fa {
  color: rgb(255, 136, 0);
  font-size: 20px;
  font-style: calibri;
}

.allowance-baggage span {
  color: rgb(255, 136, 0);
  font-style: calibri;
  font-size: 12px;
}

.city-tt-f6 p {
  margin-top: -17px;
  margin-bottom: -4px;
  font-size: 12px;
  font-style: calibri;
  color: #000;
}

.baggagge-cacel {
  display: flex;
}

.baggagge-f5 {
  display: flex;
  justify-content: end;
}

.baggagge-f5 p {
  color: rgb(255, 136, 0);
}

.policy-city {
  color: #000 !important;
  margin-bottom: 3px;
}

.allowance-baggage-1 span {
  color: #d3d3d3;
  font-size: 13px;
  font-style: calibri;
}

.baggagge-f43 span {
  color: #d3d3d3;
  font-size: 13px;
}

.card-tic-f2 {
  border: 1px solid #d3d3d3;
  margin-top: 5px;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;
}

.card-tic-f2 .ant-card-body {
  padding: 4px !important;
}

.contact-person-d4 p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  font-style: calibri;
}

.contact-person-d4 span {
  color: #000000;
}

.details-airlines-contact {
  padding-top: 0px;
}

.modify-contact-btn {
  text-align: end;
}

.tic-btn-contact2 {
  background: rgb(255, 136, 0) !important;
  text-align: center;
  font-style: calibri;
  color: #fff;
  font-size: 14px;
  outline: 0;
  border: 0;
  font-weight: 600;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .city-from-d3 {
    display: block;
  }

  .dotted-line-flight {
    width: 100%;
    margin-top: 5px;
  }
}

.booking-flight-details-info {
  border: 0;

  .termCon_wrapper {
    padding: 0 10px;
    text-align: justify;

    p {
      font-size: 14px;
      margin-bottom: 2px;
    }

    .description {
      margin-left: 10px;

      .title {
        font-weight: 800;
        font-style: calibri;
        font-size: 14px !important;
      }

      p {
        font-size: 13px !important;
        font-style: calibri;
      }
    }

    .cancellation-wrapper {
      max-width: 400px;
      margin: 0 auto;
      width: 100%;

      .policytitle {
        text-align: center;
        font-weight: 800;
        font-style: calibri;
      }
    }
  }

  .farerules {
    div {
      font-size: 14px;
      font-style: calibri;
    }
  }
}

.outer-box {
  border: 2px solid transparent;
  border-radius: 10px;

}

.inner-box1 {
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;
  background-color: #ffd483;

  @include mobile {
    width: 40%;
    background-color: transparent;
    font-weight: bold;
  }
}

.inner-box2 {
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;

  @include mobile {
    width: 60%;
  }
}

.inner-box-for-details {
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;

  @include mobile {
    width: 100%;
  }
}

.inner-box-3 {
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;
}

.inner-box2-1 {
  border: 2px solid transparent;
  width: 25%;
  text-align: center;
  font-style: calibri;
  overflow-wrap: break-word;

  @include mobile {
    width: 100%;
    text-align: left;
  }
}

.mainbox-fro-all {
  border: 2px solid transparent;

  @include mobile {
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
  }
}

.p-tag-use {
  color: orange;
  font-style: calibri;
  font-weight: 550;
}

// .inner-box2-1{
//   .fa-long-arrow-right{

//   }
// }

.inner-box2-1 span {
  font-style: calibri;
  max-width: 100%;
}

.class-for-tickitid {
  border: 2px solid transparent;
  text-align: right;
  width: 50%;
  font-style: calibri;

  @include mobile {
    width: 100%;
    text-align: left;
  }
}

.class-for-details {
  border: 2px solid transparent;
  text-align: left;
  width: 50%;
  font-style: calibri;

  @include mobile {
    width: 20%;
  }
}

.fare-total-gst {
  border-right: 0.5px solid rgb(223, 223, 223);
  width: 44%;

  @include mobile {
    width: 100%;
  }
}

.fare-total-gst1 {
  //  border: 2px solid black;
  width: 50%;

  @include mobile {
    width: 100%;
    margin-top: 2%;
  }
}

.manual-table-css {
  width: 100%;
  text-align: center;
}

.manual-table-topcolor {
  background-color: #ffd483;

}

