@import "../../assets/variableStyles.scss";
$primary: #f0802d;
$secondry: #05004e;
.search-block {
  .class-container {
    .ant-radio-wrapper {
      color: #c7436b;
      font-weight: bold;
    }
  }
  .class-type {
    color: #c7436b;
    font-weight: bold;
    font-size: 14px;
    @include mobile {
      padding-left: 11px;
    }
  }

  .search-body {
    // padding: 0 50px;
    background-color: #fff !important;
    border-radius: 10px;
    padding: 8px 25px !important;
    position: relative;

    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    /*  border: 1px solid #05004e; */
    @include mobile {
      padding: 25px;
    }
    .search-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .search-inputs {
      width: 100%;
    }
    .search-inputs-btn {
      background-color: #05004e;
      color: #fff;
    }
    .tg-primary-btn {
      background-color: #f0802d;
      color: #fff;
      border-color: #f0802d;
      height: 43px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      &:hover {
        background-color: #05004e;
        border-color: #05004e;
        color: #fff;
      }
      &:active {
        background-color: #05004e;
        border-color: #05004e;
        color: #fff;
      }
    }

    .from_wrapper {
      position: relative;
      .icon_wrapper {
        position: absolute;
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        color: #f0802d;
        font-size: 16px;
      }
      .ant-select-selector {
        padding: 0 11px 0 30px !important;
        .ant-select-selection-item {
          // line-height: 48px !important;
          color: #8d9190 !important;
          font-size: 16px;
        }
      }
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
    }

    .search-inputs-swap-icon {
      text-align: center;
      color: #c7436b;
      .anticon svg {
        font-size: 22px;
        margin-top: 9px;
        @include mobile {
          margin-top: -14px;
        }
      }
    }
    .paxmodal {
      .ant-input {
        cursor: pointer;
        font-size: 16px;
        color: #8d9190 !important;
      }
    }
  }
}
.combined-search-block {
  .search-row {
    align-items: normal !important;
  }
}
.mobile-fbt-tabs .ant-collapse-header {
  display: block;
}

.mobile-fbt-tabs .ant-collapse-header {
  display: block;
  color: #ffffff !important;
  font-size: 17px;
  text-align: end;
  border: 1px solid #f0802d;
  margin-left: auto;
  width: 173px;
  border-radius: 5px !important;
  line-height: 15px !important;
  background: #f0802d;
}
.mobile-fbt-tabs .ant-collapse-header:hover {
  background-color: #05004e;
  color: #fff;
  border: 1px solid #05004e;
}
.mobile-fbt-tabs .ant-collapse-arrow {
  right: 132px;
  color: #ffffff !important;
  font-size: 17px !important;
  left: auto !important;
}
@media only screen and (max-width: 768px) {
  .mobile-fbt-tabs .ant-collapse-header {
    display: block;
    color: #05004e !important;
    font-size: 17px;
    text-align: start !important;
    border: 0px solid;
    margin-left: 0;
    width: auto;
    border-radius: 0px !important;
    background: transparent;
  }
  .mobile-fbt-tabs .ant-collapse-arrow {
    right: 19px;
    color: #05004e !important;
    font-size: 17px !important;
    left: auto !important;
  }

  .mobile-fbt-tabs .ant-collapse-header:hover {
    background-color: transparent !important;
    color: #05004e !important;
    border: 0px solid #05004e !important;
  }
  // .row-from-background .ant-form-item {
  //   margin-bottom: 11px !important;
  // }
}

.class-container span {
  color: #000000;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
  overflow: visible;
}
.row-from-background .ant-select-selector {
  // font-size: 16px !important;
  border: 1px solid#efefef !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  color: #8d9190;
  // line-height: 48px;
  // text-align: left;

  // caret-color: #a1a9c3;
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  // transition: border 0.15s ease;
}
.from-return-date {
  // font-size: 16px !important;

  border: 1px solid #f1f2f6 !important;
  color: #434343;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px !important;
  // text-align: left;
  // letter-spacing: 0.3px;
  // caret-color: #a1a9c3;
  // transition: border 0.15s ease;
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
}
.adult-age-per {
  // font-size: 16px !important;

  border: 1px solid #f1f2f6 !important;
  color: #434343;

  border-radius: 4px !important;
  // text-align: left;
  // letter-spacing: 0.3px;
  caret-color: #a1a9c3;
  // transition: border 0.15s ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  .passangericon {
    width: 19px;
    height: 16px;
    filter: invert(43%) sepia(35%) saturate(6781%) hue-rotate(321deg)
      brightness(90%) contrast(84%);
  }
}

.search-body {
  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    font-size: 16px;
    color: #8d9190 !important;
  }
}

.search-body .ant-select-item-option-content {
  font-weight: 400;
}
.search-body .ant-picker-input > input {
  font-size: 16px;
  font-weight: 400;

  color: #8d9190 !important;
}
.search-body .ant-picker-input > input::placeholder {
  color: #8d9190 !important;
  font-weight: 400 !important;
}

.search-body {
  .ant-picker-input {
    input {
      order: 2 !important;
    }

    .ant-picker-suffix {
      margin: 0 4px 0 0;
      color: $primary;
      order: 1 !important;
    }
  }
}
.ant-select-item-option-content {
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // font-size: 12px;
  // font-weight: normal;
  font-weight: 600;
}

.bdpoint {
  .ant-select-item-option-content {
    font-size: 12px;
    font-weight: normal;
    .pointTooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.ant-picker-header-view {
  font-weight: 700;
}
// .row-from-background .ant-select-selection-search-input {
//   height: 38px;
//   font-weight: 400;
//   color: #8d9190 !important;
//   font-size: 18px;
// }
