@import "../../assets/variableStyles.scss";

.boottom {
  border-bottom: 3px solid transparent;
}

.collase {
  border: 2px solid transparent;
}

.check-box-check {
  border: 2px solid transparent;
  position: absolute;
  top: -65%;
}

.css-for-row {
  border: 2px solid transparent;
  margin-left: 1%;
}

.rows-of-more {
  border: 2px solid transparent;
  max-width: 13%;

  @include mobile {
    max-width: 100%;

  }
}

.mr-none {
  border: 2px solid transparent;
  margin-top: 17%;
}

.primary-btn {
  width: 100%;

  @include mobile {
    width: 100%;
  }
}

.search-inputs {
  width: 100%;
  font-size: 14px;

  @include mobile {
    width: 100%;
  }
}

.bus-cols-info {
  .mr-none {
    border: 2px solid transparent;

    @include mobile {
      margin-top: 5%;
    }
  }
}

.bus-search-form {
  border: 2px solid transparent;
  width: 1000px;

  @include mobile {
    width: 100%;
  }
}

.check-margin2 {
  border: 2px solid transparent;
  margin-top: 2.5%;

  @include mobile {
    height: auto;
  }
}

.flight_search_box {
  input {
    border-color: #bcd5f5;
  }

  background: #fff;
  // padding: 0 20px;
  border-radius: 5px;

  // margin-top: 0;
  .flight-header {
    margin-bottom: 4px;

    label {
      display: flex;
      align-items: flex-end;
      cursor: pointer;

      &:active {
        color: #1c2b39;
        font-weight: 700;
      }
    }

    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }

      .col {
        padding-left: 0;

        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 700;
          line-height: 19px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;

      label {
        font-size: 11px;
        font-weight: 700;
        color: #1c2b39;
        margin-bottom: 0;
      }

      .flight-search-btn {
        background-color: #e2076a;
        color: #fff;
        border-color: transparent;

        &:hover {
          background-color: #e2076a;
        }
      }
    }

    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: Calibri;
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          &:hover {
            color: red !important;
          }
        }
      }
    }

    .pax-modal {
      position: absolute;
      z-index: 1;
      left: -101px;
      background: #fff;
      width: 280px;

      // margin-top: 20px;
      .pax-modal-wrapper {
        padding: 10px 20px;
        border: 1px solid #bcd5f5;

        li {
          display: flex;
          list-style-type: none;
          border-bottom: 1px solid #dcdee3;
          margin-bottom: 2px;
          margin-top: 8px;
          padding-bottom: 8px;

          &:last-child {
            border-bottom-color: transparent;
          }

          .pax-label {
            flex-grow: 1;

            p {
              margin-bottom: -6px;
              font-size: 16px;
              font-weight: bold;
              color: #1c2b39;
            }

            span {
              font-size: 12px;
              font-weight: 400;
              color: #969dac;
            }
          }

          .pax-count {
            flex-grow: 1;
            align-self: baseline;
            display: flex;
            justify-content: space-evenly;

            .fa {
              background: #0775e2;
              padding: 4px;

              color: #fff;
              line-height: 1;
              vertical-align: sub;
            }

            .fa-minus {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            .fa-plus {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }

        .pax-ready-btn {
          border-radius: 3px;
          background: #fff;
          padding: 6px;
          color: #0775e2;
          font-size: 11px;
          font-weight: 700;
          border: 2px solid #0775e2;
          margin-top: 20px;

          &:hover {
            background: #0775e2;
            color: #fff;
          }
        }
      }
    }
  }

  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .place-box {
      width: 37%;
      padding-right: 10px;
      padding-left: 10px;
    }

    .departure-date-box {
      width: 23%;
    }

    .city-increment {
      margin-left: -4px;
    }
  }
}

.search-row {
  align-items: flex-end;
}

.from-to-inputs {
  .ant-row {
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-control {
      width: 100%;
    }
  }
}

.ant-collapse {
  border: 0;

  .ant-collapse-header {
    background-color: rgb(255, 255, 255) !important;
    color: #05004e !important;
  }

  .ant-collapse-header {
    .ant-collapse-arrow {
      margin-left: -1.5%;
    }
  }

  .ant-collapse-item {
    border: 0;
  }
}

.collapse-margin {
  .ant-collapse-item {
    .ant-collapse-content-box {
      padding: 0%;
    }
  }
}

.hotel-search-form {
  width: 100%;

  @include mobile {
    width: 100%;
  }
}

.ant-select-selector {
  border: 1px solid #efefef !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  color: #8d9190;
}

.block-of-info-bus {
  .hotels-wrapper {
    #busForm {
      .ant-collapse-header {
        max-width: 140px;

        span {
          padding-top: 0 !important;
        }
      }
    }
  }
}

.select-api .ant-collapse-header {
  max-width: 100% !important;
  width: 100%;
}

.collapse-margin {
  width: 100%;
  margin-left: 0%;
}

.hotels_search_box {
  border: 2px solid transparent;
  width: 100%;

  @include mobile {
    width: 100%;
  }
}

.radio-button-margin {
  border: 2px solid transparent;
  margin-top: 3%;
}