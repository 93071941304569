@import "../../assets/variableStyles.scss";

.timeout-popup-main{
    border: 2px solid transparent;
    text-align: center;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
}
.timeout-popup-main1{
    border: 2px solid rgb(209, 209, 209);
    width: 40%;
    border-radius: 50%;
    text-align: center;
    @include mobile{
        width: 50%;
    }
}
.timeout-popup-main2{
    border: 2px solid transparent;
    text-align: center;
    width: 100%;
    margin-top: 2%;
}
.timeout-img{
width: 100%;
border-radius: 50%;
}