@import "../../assets/variableStyles.scss";
.privacypol-img {
    background-image: url("../../assets/images/policy-new.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    margin-bottom: 50px;
}

.privacy-policy-n {
    margin: 0 auto;
    // width: 1100px;
    width: 97%;
    background: mintcream;
    padding: 20px;
    box-shadow: 2px 3px 8px grey;
    margin-bottom: 20px;
    @include mobile{
        width: fit-content;
    }

    h2 {
        display: flex;
        justify-content: center;
        font-weight: 600;
    }

    .pripolicyterms {
        h3 {
            font-size: 24px;
            margin-top: 20px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 5px;
            font-weight: 600;
        }

        ol,
        ul {
            margin-left: 20px;
            font-size: 14px;
        }

        p {
            font-size: 14px;
            line-height: 1.5;
            color: black;
            margin-bottom: 12px;
        }

    }
}