@import "../../../assets/variableStyles.scss";
.flights-seperated-view {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0px;
  }
  .edit-onward {
    margin-bottom: 15px;
    .card-results {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
      padding: 15px;
      margin-bottom: 15px;
      .card-results-travel-details {
        display: flex;
        justify-content: space-between;
        @include mobile {
          flex-direction: column;
        }
        .onward_wrapper {
          padding-right: 10px;
          border-right: 2px solid #eee;
          color: #f0802d;
          @include mobile {
            padding-right: 5px;
            border: none;
          }
          p {
            font-weight: 600;
            margin-bottom: 3px;
            font-size: 14px;
            @include mobile {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
        .itinary_wrapper {
          flex: 1;
          max-width: 700px;
          margin-right: auto;
          @include mobile {
            max-width: 100%;
            margin-right: 0;
          }
          .itinary-details {
            display: flex;
            flex: 1;
            padding: 0 10px;
            @include mobile {
              flex-direction: column;
              padding: 0 10px;
            }
            .itinary-flight-name {
              text-align: center;
              @include mobile {
                display: flex;
                align-items: center;
              }
              .airline-img {
                max-width: 25px;
              }
              .name {
                font-weight: 600;
              }
              p {
                margin-bottom: 0;
                padding: 2px;
              }
            }
            .fromTo {
              flex: 1;
              display: flex;
              padding-right: 10px;
              flex-wrap: wrap;
              .cities-from-to {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-right: 10px;
                flex: 1;
                .loc_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    font-weight: 600;
                    margin-right: 5px;
                  }
                  .code {
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .slectedtiming {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            .flight-timings {
              flex: 1;
            }
            .timing_wrapper {
              flex-wrap: wrap;
              .plane-timings {
                p {
                  font-size: 14px;
                }
              }
            }
            .travel-time {
              p {
                font-size: 14px;
              }
            }
            .details-btn {
              display: none !important;
            }
          }
        }
      }
    }

    .btncontainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        flex-direction: row;
        padding: 10px 10px 0 10px;
      }
      .price {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        @include mobile {
          flex: 1;
        }
        span {
          font-size: 12px;

          margin-right: 5px;
        }
      }
      .tg-primary-btn {
        font-size: 12px;
        padding: 6px 13px;
        height: fit-content;
        width: fit-content;
      }
    }
    .returntext {
      color: #f0802d;
      font-weight: 600;
    }
  }
  // .resultsScrollWrapper {
  //   width: 100%;
  //   height: 100%;
  //   overflow-y: scroll;
  //   padding-right: 17px;
  //   box-sizing: content-box;
  //   max-height: 740px;
  // }
  .seperated-price-details {
    background-color: #fff;
    text-align: center;
    padding: 15px;
  }
  .seperated-view-row {
    margin-bottom: 25px;
  }
  .trip-contents {
    // padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    // padding-left: 50px;
    @include mobile {
      padding-left: 5px;
    }
    &:hover {
      background: var(--leg-hover-color, rgba(234, 235, 238, 0.6));
    }
    input {
      margin-right: 10px;
    }
  }
  // .airline-img {
  //     max-width: 55px;
  //     height: 28px;
  // }
  .trip-content-left {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include mobile {
      flex-direction: column;
    }
  }
  .trip-arr-dep {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    @include mobile {
      flex-direction: column;
    }
  }

  .timings {
    i {
      color: #1c2b39;
      font-size: 15px;
      padding: 1px 5px;
    }
  }
  .duration {
    padding-left: 20px;
    text-align: center;
    @include mobile {
      padding-left: 5px;
    }
    i {
      @include mobile {
        display: none;
      }
    }
  }
  .show-more-btn {
    padding: 4px 4px 0px 80px;
    @include mobile {
      padding: 4px 4px 0px 26px;
    }
    button {
      border: solid 1px #0775e2;
      color: #0775e2;
      line-height: 25px;
      font-size: 12px;
      font-weight: 700;
      &:hover {
        background: none;
        color: #0775e2;
        font-weight: 700;
      }
      i {
        color: #0775e2;
        font-weight: 700;
      }
    }
  }
  .share-section {
    font-size: 14px;
    color: #007baa;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
  .currencyType {
    color: #969dac;
    font-size: 18px;
    letter-spacing: 1px;
    padding-right: 4px;
    font-weight: 700;
    @include mobile {
      font-size: 15px;
    }
  }
  .different-amenities {
    font-size: 16px;
    color: rgb(29, 160, 35);
    // margin-left: 100px;
    // margin-left: 140px;
    @include mobile {
      // margin-left: 20px;
    }
  }
  /* Tooltip-styles start */
  // .amenities-tool-tip {
  //     @include mobile {
  //         display: none;
  //     }
  // }
  .ament-tooltip {
    padding-left: 40px;
  }
  .amenities-tooltip .amenities-tooltip > .tooltip-inner {
    // max-width: 100%;
    min-width: 450px !important;
    @include mobile {
      max-width: 300px !important;
    }
  }
  .ant-tooltip {
    max-width: auto !important;
    //   left: 200px !important;
    @include mobile {
      max-width: 300px !important;
    }
  }
  // .ant-tooltip-content {
  //     min-width: 450px !important;
  //     background: #0e0e0e !important;
  //     @include mobile {
  //         max-width: 300px !important;
  //     }
  // }

  .tooltip-content {
    display: flex;
    justify-content: center;
    padding: 20px 20px !important;
    //   min-width: 450px !important;
    .flight-amenities {
      padding: 0px 5px;
      .flight-src-dest {
        h6 {
          color: #fff;
          padding-bottom: 10px;
        }
      }
      .amenity-types {
        p {
          line-height: 1.4;
          font-size: 12px;
          i {
            padding-right: 6px;
          }
        }
        .aminities-color {
          color: #1da023;
          font-size: 12px;
        }
      }
    }
  }
  /* Tooltip-styles end */

  .trip-total-details {
    @include mobile {
      order: 1;
    }
  }
  .trip-choose-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-left: solid 1px #ccc;
    @include mobile {
      order: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      text-align: left;
      padding: 0px 30px;
    }
  }

  /*********** Antd styles ********/
  .flight-results-container {
    height: 100%;
    .ant-card-body {
      padding: 0;
    }
    .flight-trip-details {
      // padding: 15px;
      background-image: linear-gradient(180deg, #fafcfd, #fff);
      .itinary-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .itinary-flight-name {
          @include tab {
            display: flex;
            align-items: center;
            flex: 1 0 50%;
            order: 1;
          }
          .airline-img {
            max-width: 35px;
          }
          .name {
            font-size: 14px;
            margin-top: 6px;
            margin-bottom: 2px;
            font-weight: 600;
            @include tab {
              margin-left: 3px;
            }
            .code {
              font-weight: 500;
              font-size: 12px;
            }
            @include mobile {
              font-size: 12px;
              .code {
                font-size: 10px;
              }
            }
          }
        }

        .fromTo {
          display: flex;
          flex: 1;
          padding: 0 10px;
          justify-content: center;
          @include tab {
            flex: 0 1 100%;
            order: 3;
            padding: 10px 0 12px 0;
          }
          @include mobile {
            margin-left: 0px;
            font-size: 15px;
            font-weight: 700;
            flex-direction: column;
            justify-content: flex-start;
          }
          .cities-from-to {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-right: 10px;
            flex: 1;
            @include mobile {
              padding-right: 0;
            }
            .loc_wrapper,
            .terminal_wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 10px;
              font-weight: 600;
              @include mobile {
                p {
                  margin-bottom: 2px;
                }
              }
            }
            .loc_wrapper {
              .name {
                font-weight: 600;
                font-size: 16px;
                @include xsmobile {
                  font-size: 14px;
                }
              }
              .code {
                font-weight: 600;
                font-size: 12px;
                margin-left: 2px;
                @include xsmobile {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
    .code-line {
      width: 35%;
      height: 2.4px;
      background: #0775e2;
      margin: auto;
      @include mobile {
        font-size: 12px;
        padding: 0px 15px;
        align-items: center;
        text-align: center;
      }
    }

    .choose-btn-block {
      text-align: center;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .choose-btn-price {
        margin-bottom: 10px;
      }
    }
  }

  .flight-details-modal {
    width: 840px !important;
    .ant-modal-body {
      padding: 0;
      max-height: 500px;
      overflow-y: scroll;
    }
    .ant-modal-footer {
      display: none;
    }
    .all-times-local {
      padding: 16px 24px;
      border-bottom: 1px solid #ccc;
      p {
        margin-bottom: 0;
      }
    }
    .from-to-modal-details {
      padding: 16px 24px;
    }
    .more-details-row {
      align-items: center;
      .more-details-list {
        p {
          &:nth-child(1) {
            font-size: 18px;
            text-transform: uppercase;
            color: #00164d;
            margin-bottom: 0;
          }
          &:nth-child(2) {
            margin-bottom: 0;
            font-size: 12px;
          }
        }
      }
      .more-details-line {
        height: 2px;
        background-color: #0775e2;
        margin: auto;
        margin-left: -15px;
      }
      .trip-duration-time {
        p {
          &:nth-child(1) {
            color: #969dac;
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 700;
          }
          &:nth-child(2) {
            color: #00164d;
            margin-bottom: 0;
          }
        }
      }
    }
    .total-flight-details {
      padding: 16px 24px 16px 60px;
      background-color: #f6f7f8;
      .to-fro-flight-details {
        display: flex;
        position: relative;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 3px solid #0775e2;
          position: absolute;
          left: -33px;
          top: 4px;
        }
        .from-city-flight-details {
          margin-right: 25px;
        }
      }
      .collapsed-card {
        padding: 25px 0;
        position: relative;
        &::before {
          content: "";
          height: 100%;
          position: absolute;
          background-color: #0775e2;
          width: 3px;
          left: -29px;
          top: 0;
        }
        .ant-card-body {
          padding: 0;
        }
        .ant-collapse {
          border: none;
        }
        .ant-collapse-item {
          border: none;
        }
        .ant-collapse-header {
          background-color: white;
        }
      }
    }
  }

  .acc-body {
    .acc-body-section {
      display: flex;
      padding: 7px 2px;
      align-items: baseline;
      .icon {
        margin-right: 7px;
        color: #1da023;
      }
      p {
        color: #1da023;
      }
      &:nth-child(1) {
        padding-right: 25px;
      }
    }
    .seat-sec {
      i {
        padding-right: 7px;
        color: #505c75;
      }
      p {
        color: #505c75;
      }
    }
    .flight-class {
      .ec-class {
        padding-left: 12px;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .tooltip-data {
    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
    }
    p {
      font-size: 12px;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .ant-tooltip-content {
    min-width: 100% !important;
    border-radius: 10px;
    .ant-tooltip-inner {
      padding: 10px 15px;
    }
  }
  .ant-tooltip {
    max-width: 100% !important;
  }
  .luggage-tooltip {
    p {
      color: #1da023;
      margin-bottom: 10px;
      display: flex;
      align-items: baseline;
      .ant-icon,
      i {
        font-size: 18px;
        margin-right: 5px;
      }
      &:nth-child(1) {
        color: #fff;
      }
      &:nth-child(2) {
        color: #fff;
      }
    }
  }
  .collapse-header {
    .flight-icon {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        height: 40px;
        width: 40px;
      }
      .flight-names-block {
        p {
          font-size: 12px;
          margin-bottom: 0;
          &:nth-child(1) {
            font-size: 20px;
          }
        }
      }
    }
    .ant-row {
      align-items: center;
    }
    .modal-flight-icon {
      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
    .timer-flight-icon {
      span {
        margin-right: 10px;
      }
      .icon {
        font-size: 18px;
      }
    }
  }
  .combined-view {
    margin-bottom: 10px;
    .flight-trip-details {
      padding: 10px;
    }
  }
  /*********** Seperate View ******/
  .seperate-view {
    .right-flight-details {
      border-bottom: 1px solid #ccc;
      padding: 10px;
      &:last-child {
        border: none;
      }
    }
  }
  .break-journey-city {
    .code-line-break {
      height: 7px;
      width: 7px;
      background-color: #0775e2;
      border-radius: 50%;
      margin: auto;
      margin-top: 10px;
      &::before {
        content: "";
        background-color: #0775e2;
        width: 11%;
        // top: 22px;
        height: 2px;
        left: 55px;
        position: absolute;
      }
      &::after {
        content: "";
        background-color: #0775e2;
        width: 11%;
        // top: 22px;
        height: 2px;
        right: 53px;
        position: absolute;
      }
    }
    .break-city-name {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .change-timer {
    background-color: #eaebee;
    padding: 24px 60px;
    display: flex;
    align-items: center;
    .change-text {
      color: #e0760b;
      font-weight: 600;
      margin-right: 30px;
      display: flex;
      align-items: center;
      span {
        font-size: 20px;
      }
      .icon-text {
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .time-for-interchange {
      font-size: 13px;
    }
  }
  // .fromTo .code {
  //   font-size: 14px;
  // }
}
.ant-modal-header {
  background-color: #05004e !important;
}
.from-city-flight-details span {
  font-weight: 700;
}
