.ourteam-values{
    background-image: url("../../assets/images/our-team.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 350px;
        width: 100%;
        margin-bottom: 50px;
}
.common-headings{
   /*  padding-top: 15%; */
    text-align: center;
    color: #05004e;
  
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 0;
}
.common-paras{
    text-align: center;
    color: #000;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 23px;
}

.ourteam-bg{
    background: transparent !important;
}
.cms-pages-width{
    max-width: 1140px !important;
    margin: auto;
    padding-bottom: 35px;
}
/* .cms-pages-collaspe .ant-collapse-borderless{
    background-color: #05004e !important;
    border: 1px solid #eee !important;
} */
.cms-pages-collaspe .ant-collapse-header{
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;

}
.cms-pages-collaspe .ant-collapse-content-box{
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #000 !important;
    border-top: 1px solid #e4e8eb;
    padding-top: 17px !important;
    
}