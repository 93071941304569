.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; // Nav bar background color
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  
    .navbar-logo {
      a {
        display: flex;
        align-items: center;
        img {
          height: 40px; // Set your logo height
          width: auto;
        }
      }
    }
  
    .navbar-right {
      .home-btn {
        background-color: rgb(240, 128, 45); // Primary button color
        color: #fff;
        border-radius: 4px;
        font-size: 16px;
        padding: px 16px;
  
        &:hover {
          background-color: #1890ff;
        }
      }
    }
  }
  