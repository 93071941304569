@import "../../assets/variableStyles.scss";

.header-container {
  background: rgba(7, 22, 36, 0.35);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 70;
  .wrapper {
    max-width: 1280px;
    background-color: transparent;
    margin: auto;
    .header {
      width: 100%;
      background-color: transparent;
      display: flex;
      // padding-left: 15px;
      // justify-content: space-between;
      .app-logo {
        // margin: 8px;
        // a {
        //   color: white;
        //   img {
        //     height: 50px;
        //   }
        // }
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          a {
            color: white;
            margin-right: 1rem;
            font-size: 20px;
            text-decoration: none;
            }
        }
        @include mobile {
          width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }
      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include mobile {
          align-items: flex-start;
        }
        link {
          &:hover {
            text-decoration: none;
          }
        }
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          overflow: hidden;
          @include mobile {
            display: block;
          }
          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            @include mobile {
              padding: 0;
            }
            a {
              display: flex;
              color: #fff;
              align-items: center;
              text-decoration: none;
            }
            span {
              display: block;
              // padding: 22px 7px;
              text-decoration: none;
              font-size: 16px;
              color: #fff;
              @include mobile {
                padding: 15px 0px;
              }
              &:hover {
                color: #bcd5f5;
              }
            }
          }
          .custom-drop-down {
            position: initial;
            a {
              color: #fff;
            }
            .dropdown-item {
              color: #000 !important;
            }
          }
        }
        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }
      }
    }
    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;
        @include mobile {
          padding: 28px 0 28px 20px;
        }
        .navicon {
          background: #fff;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;
        }
      }
      .user-icon {
        display: flex;
        align-items: center;
        i {
          margin-right: 20px;
          font-size: 20px;
          color: #fff;
        }
        .menu-icon {
          margin-bottom: 0;
          padding: 22px 0 28px 20px;
        }
        .menu-text {
          color: #fff;
          font-size: 10px;
          position: absolute;
          top: 30px;
          right: 11px;
        }
      }
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #fff;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked ~ .menu {
      max-height: 430px;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
    }
    .iconText {
      margin-left: 5px;
    }
    .service-links {
      align-items: center;
      .nav-link {
        color: white !important;
        font-size: 16px;
        i {
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }
    @media (min-width: 992px) {
      .header li {
        // float: left;
      }
      .header li a {
        // padding: 23px 30px;
      }
      .header .menu {
        max-height: none !important;
      }
      .header .menu-icon {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      .header {
        padding: 0 15px;
        display: block;
        position: fixed;
        top: 0;
        background: #00164d;
        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.dashboard-menu {
  max-width: 300px;
}
.menu {
  align-items: center;
  .myaacnt-text {
    a {
      color: #fff;
      margin: 0 5px;
    }
  }
  .currency-text {
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  
}
.curreny-dropdown {
  padding: 20px 0 20px 30px;
}
.ant-select-dropdown {
  z-index: 9999999999 !important;
}
.login-modal {
    width: 600px !important;
    
    .ant-modal-close-x {
      color: #000 !important;
    }
    .ant-modal-footer {
      display: none;
    }
    .close-modal-icon {
      padding-bottom: 15px;
      font-size: 20px;
      color: #000;
      text-align: end;
    }
    .ant-form-item {
     .ant-form-item-control {
       min-width: 100%;
     }
    }
    .ant-input-affix-wrapper::before {
      display: none;
    }
    .ant-form {
      padding: 10px 100px;
      @include mobile {
        padding: 0;
      }
    }
    .login-text {
      text-align: center;
    }
    .forgot-text {
      font-size: 14px;
      color: #0775e2;
      text-align: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      
    }
    .ant-col {
      margin-left: 0 !important;
      text-align: center;
      button {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .ant-input-password {
      input {
        padding-left: 15px !important;
      }
    }
    .login-alternatives {
      display: flex;
      margin: 15px 0;
      .fb-btn {
        width: 50%;
        text-align: center;
        padding: 10px 0;
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(0,0,0,.2);
        margin-right: 10px;
        p {
          margin-bottom: 0;
        }
        img {
          height: 20px;
          text-align: center;
        }
      }
      .google-btn {
        width: 50%;
        text-align: center;
        padding: 10px 0;
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(0,0,0,.2);
        p {
          margin-bottom: 0;
        }
        img {
          height: 20px;
          text-align: center;
        }
      }
    }
    .or-text {
      p {
        font-size: 14px;
        color: #000;
        text-align: center;
      }
    }
}

@include mobile {
  .mob-none {
    display: none !important;
  }
}
.mobile-user {
  color: #fff;
  font-size: 20px;
  .mobile-user-icon{
  display: flex;
  align-items: center;
  }
}
.responive-more {
  li {
    a {
      padding: 15px 0;
      margin-left: 5px;
      text-decoration: none;
    }
  }
}
.supportvalue{
  display: block;
    text-decoration: none;
    font-size: 16px;
    color: #fff !important;
    ;
}
