@import "../../assets/variableStyles.scss";
// $primary: #f0802d;
// $secondry: #05004e;

// .combined-home {
//   .header {
//     position: absolute;
//     width: 100%;
//     background: transparent;
//   }

//   .search-wrapper {
//     max-width: 1200px;
//     margin: -155px auto 50px;
//     padding: 0 15px;

//     @include mobile {
//       margin: -267px auto 50px;
//     }
//   }

//   .home-cards {
//     h3 {
//       font-size: 18px;
//       font-weight: 600;
//       color: #f0802d;
//     }
//   }

//   .planning-block {
//     // background-color: #fff;
//     background: linear-gradient(0deg, #f0802d6b, transparent);
//     border-top: 1px solid #ccc;

//     .planning-block-row {
//       max-width: 1200px;
//       margin: 0 auto;
//       padding: 50px 15px;
//       display: flex;
//       align-items: center;

//       .image-container {
//         background-image: url("../../assets/images/rep-icon.svg");
//         background-repeat: no-repeat;
//         background-size: contain;
//         height: 20em;
//       }

//       .planning-text {
//         h1 {
//           font-size: 30px;
//           font-weight: 600;
//           color: #f0802d;

//           @include mobile {
//             font-size: 23px;
//             font-weight: 600;
//             line-height: 30px;
//           }
//         }
//       }
//     }
//   }

//   .stay-tuned-block {
//     .stay-tuned-wrapper {
//       max-width: 1200px;
//       margin: 0 auto;
//       padding: 20px 5px;
//       display: flex;
//       justify-content: space-evenly;
//     }

//     background-color: #ffffff;
//     text-align: center;

//     h2 {
//       color: #f0802d;
//       margin-bottom: 16px;
//       font-size: 30px;
//       font-weight: 600;
//       color: #f0802d;
//     }

//     p {
//       color: #000000;
//       margin-bottom: 16px;
//       font-size: 16px;
//     }

//     .staytuned-input {
//       display: flex;
//       width: 40%;
//       align-items: center;

//       @media (max-width: 768px) {
//         width: 100%;
//       }

//       .ant-input-affix-wrapper {
//         height: 48px;
//         border-radius: 8px;
//         border-color: #f0802d !important;

//         .ant-input-prefix {
//           color: $primary !important;
//         }
//       }

//       button {
//         background-color: #f0802d;
//         outline: none;
//         border: 1px solid #f0802d;
//       }
//     }
//   }

//   .cards-flex {
//     display: flex;

//     .ant-card {
//       width: 100%;
//       text-align: center;

//       .ant-card-body {
//         @include xsmobile {
//           padding: 10px 5px;
//         }
//       }

//       .card-icons {
//         font-size: 25px;
//       }
//     }

//     img {
//       /* height: 115px; */
//       width: 115px;
//       margin-bottom: 15px;
//     }
//   }

//   .content-wrapper {
//     .content-cards {
//       max-width: 1200px;
//       margin: 0px auto;
//       padding: 0 15px;
//     }
//   }

//   .top-routes {
//     border-top: 1px solid #ccc;
//     // background-color: #fff;
//     background: linear-gradient(180deg, #f0802d6b, transparent);

//     .top-routes-container {
//       max-width: 1200px;
//       margin: 0 auto;
//       padding: 50px 15px;
//       display: flex;
//       align-items: flex-start;
//       justify-content: space-between;
//     }

//     .top-bus-op {
//       flex: 1;
//       max-width: 230px;
//     }

//     .top-bus-routes,
//     .top-bus-op,
//     .top-flight-routes {

//       .top-bus-heading,
//       .top-train-heading,
//       .top-trains-heading {
//         h4 {
//           font-size: 23px;
//           margin-bottom: 15px;
//         }
//       }

//       .top-bus-content,
//       .top-trains-content,
//       .top-flight-content {
//         ul {
//           li {
//             color: #05004e;
//             margin-bottom: 10px;
//             font-size: 14px;

//             &:hover {
//               color: #f0802d;
//             }
//           }
//         }
//       }
//     }
//   }

//   .adv-banner {
//     background-color: #292929;

//     .adv-banner-container {
//       max-width: 1200px;
//       margin: 0 auto;

//       padding: 20px 15px;

//       .adv-text {
//         margin-top: 70px;

//         h3 {
//           color: #f0802d;
//           font-size: 30px;
//           margin-bottom: 18px;
//           font-weight: 600;
//           font-family: GT Walsheim, Helvetica, Arial, sans-serif;

//           @include mobile {
//             font-size: 23px;

//             font-weight: 600;
//             line-height: 30px;
//             text-align: center;
//             font-family: GT Walsheim, Helvetica, Arial, sans-serif;
//           }
//         }

//         p {
//           color: #ffffff;

//           @include mobile {
//             font-size: 16px;
//             text-align: center;
//           }
//         }
//       }

//       .adv-row {
//         align-items: center;
//       }
//     }

//     .image-carousel {
//       .bus-bgimg {
//         width: 300px;
//         border-top-right-radius: 30px;
//         border-bottom-right-radius: 30px;
//       }

//       // img {
//       //   height: 420px;
//       //   margin: auto;
//       // }
//     }
//   }
// }

// .play-store-btns {
//   img {
//     height: 60px;
//     margin-right: 25px;

//     @include mobile {
//       display: block;
//       margin-left: auto;
//       margin-right: auto;
//       margin-bottom: 10px;
//     }
//   }
// }

// .tg-primary-btn {
//   background-color: #f0802d;
//   color: #fff;
//   border-color: #f0802d;
//   height: 48px;
//   border-radius: 8px;
//   font-weight: 700;

//   &:hover {
//     background-color: #05004e;
//     border-color: #05004e;
//     color: #fff;
//   }

//   &:active {
//     background-color: #f0802d;
//     border-color: #f0802d;
//     color: #fff;
//     height: 48px;
//     border-radius: 8px;
//     font-weight: 700;
//   }
// }

// .travel-21 h5 {
//   font-weight: 600;
//   line-height: 24px;
//   font-size: 16px;
//   color: #425486;
//   text-align: center;
// }

// .travel-21 p {
//   color: #425486;

//   font-size: 16px;
//   line-height: 24px;
//   font-style: Calibri;
//   text-align: center;
// }

// .travel-21 img {
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// }

// .tarvel-22 p {
//   color: #05004e;

//   font-size: 16px;
//   line-height: 24px;
//   font-style: Calibri;
//   text-align: center;
// }

// .travels-bar {
//   /*  border-bottom: 1px solid #ccc; */
//   margin-top: 20px;
// }

// // .ant-btn-primary {
// //     background-color: #09a651;
// //     border: none;
// //     &:hover {
// //     background-color: #09a651;
// //     }
// // }
// .search-wrapper .combined-search-block {
//   border-bottom: 0px solid #d9d9d9;
//   border-top: 0px solid #d9d9d9;
// }

// .planning-text-mobile p {
//   color: #05004e;
//   font-size: 16px;
//   line-height: 24px;
//   font-style: Calibri;
// }

// .adv-text p {
//   font-size: 16px;
//   line-height: 24px;
//   font-style: Calibri;
// }

// @media screen and (max-width: 768px) {
//   .search-wrapper .ant-collapse-header {
//     display: block;
//     color: #000000 !important;
//     font-size: 23px;
//     display: none;
//   }

//   .planning-text-mobile {
//     margin-top: 20px;
//   }

//   .planning-text-mobile h1 {
//     text-align: center;
//   }

//   .planning-text-mobile p {
//     text-align: center;
//   }

//   .image-container {
//     background-position: center;
//   }

//   .play-store-btns {
//     margin-bottom: 25px;
//   }

//   .tg-primary-btn {
//     width: 100%;
//   }
// }

// .bus-to-from {
//   font-size: 24px !important;
//   font-weight: 700;
// }

// .buses-route-ul li {
//   color: #425486 !important;
//   font-size: 16px !important;
//   line-height: 24px;
//   font-style: Calibri;

//   span {
//     cursor: pointer;
//   }
// }

// .buses-route-ul-mobile li {
//   color: #05004e !important;
//   font-size: 16px !important;
//   line-height: 24px;
//   font-style: Calibri;
//   padding-bottom: 10px;

//   span {
//     cursor: pointer;
//   }
// }

// .top-routes-mobile {
//   /*  padding: 50px 20px; */
//   display: none;
// }

// .top-routes-mobile {
//   /* padding: 50px 20px; */
//   background-color: #fff;
// }

// .top-routes-container-mobile {
//   max-width: 1200px;
//   margin: auto;
//   padding-left: 40px;

//   @include mobile {
//     padding-left: 0px;
//   }
// }

// .top-routes-mobile {
//   display: none;
// }

// .routes-block-new {
//   display: none;
// }

// @media only screen and (max-width: 768px) {
//   .top-routes-mobile {
//     display: block !important;
//   }

//   .new-top-all-routs {
//     display: none;
//   }

//   .bus-routes-collaspe {
//     background-color: #05004e !important;
//     border: 1px solid #eee !important;
//   }

//   .bus-routes-collaspe .ant-collapse-item {
//     border-bottom: 1px solid #eee;
//   }

//   .bus-routes-collaspe .ant-collapse-header {
//     color: #fff !important;
//     font-size: 16px !important;
//     font-weight: 700 !important;
//     line-height: 20px !important;
//   }
// }

// .buses-route-ul-top li {
//   color: #425486 !important;
//   font-size: 16px !important;
//   line-height: 7px;
//   font-style: Calibri;
//   display: inline-block;
//   padding: 0px 10px 10px 0px;
// }


// ///new
// /// 


// .home-ban-im {
//   position: relative;

//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   // position: relative;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   // height: 100vh;

//   .homebann-im {
//     width: 100%; // Make the image take up full width
//     height: 100%;
//     object-fit: cover; // Ensures the image covers the entire area without distortion
//     object-position: center;
//   }

//   //   width: 100%;
//   //   height: auto;
//   //   /* Maintain the aspect ratio */
//   //   object-fit: cover;
//   // }
// }

// .image-container img {
//   // width: 100%;//
//   // height: 550px;
//   // display: block;//
//   // width: 100%;
//   // height: 100%;
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   position: relative;
//   width: 100%;
//   height: 100%;
// }

// .log-im {
//   position: absolute;
//   top: 86px;
//   right: 200px;
//   z-index: 10;
//   width: 360px;
//   border-radius: 8px;
//   box-shadow: 2px 3px 12px #6e5f4d;
// }

// .login-con-banner {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   /* Full height of the container */
// }

// .login-card {
//   background-color: rgb(255, 255, 255);
//   // padding: 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
//   // height: 350px;
//   // background: linear-gradient(696deg, #ffffff, #80caff);
// }

// .otp-btn {
//   width: 100%;
//   /* Button spans full width */
// }

// .login-form-forgot {
//   cursor: pointer;
//   color: #007bff;
//   text-decoration: underline;
// }

// @media (max-width: 768px) {
//   .log-im {
//     top: 20px;
//     right: 20px;
//     width: 100%;
//   }

//   .login-card {
//     width: 90%;
//     /* For smaller screens */
//   }
// }


.combined-home {
  display: flex;
  flex-direction: column;

  // height: 100vh;
  @include mobile {
    // height: 70vh;

    background: linear-gradient(237deg, #f0802d, #fcd78c);

  }

  .home-content {
    flex: 0 1 90%; // 90% of screen height
    display: flex;
    position: relative;

    .home-ban-im {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .image-container {
        width: 100%;
        height: 100%;

        img.homebann-im {
          width: 100%;
          height: 100%;
          object-fit: cover; // Ensures image covers the area without distortion
          object-position: center;

          @include tab {
            width: 323%;
          }
        }

        @include mobile {
          display: none;
          background: #fab24f;
        }

        @include tab {
          display: none;
          background: #fab24f;
        }

       

      }

      @include mobile {
        background: linear-gradient(237deg, #f0802d, #fcd78c);
      }

      .log-im {
        position: absolute;
        top: 50%;
        right: 12%;
        transform: translateY(-50%); // Center vertically
        width: 360px;
        max-width: 100%;
        z-index: 10;
        border-radius: 8px;
        box-shadow: 2px 3px 12px #6e5f4d;

        @include mobile {
          // position: relative;
          display: contents;
          // width: 76%;
        }
        @include tab {
          // position: relative;
          // right: 14px;
          // transform: none;
          // top: 10%;
          // width: 76%;
          display: contents;
        }
       
      }
    }
  }

  .footer-container {
    flex: 0 1 10%; // 10% of screen height for the footer
    background-color: #f8f9fa; // Footer background
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

@media (max-width: 768px) {
  .log-im {
    width: 90%;
    right: 5%;
    top: 5%;
    transform: translateY(0); // Reset vertical positioning on smaller screens
  }

  .login-card {
    width: 100%;
    padding: 15px;
  }
}