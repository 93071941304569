.termsofconditions-new {
    background-image: url("../../assets/images/terms-new.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    margin-bottom: 50px;
}

.termspolicy-new {
    background-image: url("../../assets/images/faq-new.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    margin-bottom: 50px;
}


.section {
    margin-bottom: 30px;
}

.terms-container {
    padding: 20px;
    max-width: 1100px;
    margin: 16px auto;
    background: mintcream;
    box-shadow: 2px 3px 8px grey;


    h1 {
        font-size: 30px;
        display: flex;
        justify-content: center;
        font-weight: 600;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 15px;
        border-bottom: 2px solid #eee;
        padding-bottom: 10px;
    }

    h3 {
        font-size: 24px;
        margin-top: 20px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        font-weight: 600;
    }

    ol,
    ul {
        // margin-left: 20px;
        font-size: 14px;

        li {
            list-style: circle;
            margin: 10px 0;
        }
    }

    p {
        font-size: 14px;
        line-height: 1.5;
        color: black;
        padding-bottom: 20px;
    }

    a {
        color: #007bff;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}