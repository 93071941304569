.combined-navbar-header {
  /* position: absolute; */
  z-index: 1;
  background-color: transparent;
  width: 100%;
  .combined-navbar-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    align-items: center;
    .ant-menu-vertical {
      border: none;
    }
  }
}
.menu-items {
  background-color: transparent;
  display: flex;
  border: none;
  color: #05004e;
  .ant-menu-item-only-child {
    a {
      color: #05004e;
    }
    &:nth-child(2) {
      border: 1px solid #fff;
      border-radius: 5px;
      &:focus {
        border-radius: 5px;
      }
      &:hover {
        color: #04598f;
      }
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
  }
}
.logo {
  display: flex;
  align-items: center;
  height: 70px;
  width: 140px;
  img {
    height: 21px;
    width: 100%;
    max-width: 41px;
  }
}
.modal-login-new .ant-modal-footer {
  display: none;
}

.modal-login-new {
  border-radius: 8px;
  width: 368px !important;
  height: auto;
}

.modal-login-new .ant-modal-content {
  border-radius: 8px !important;
  height: 300px;
}

.login-person {
  text-align: center;
  margin-bottom: 50px !important;
  font-size: 18px;
  line-height: 32px;
  color: #132968;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}
.modal-login-new .ant-modal-close-x {
  color: #000 !important;
}

.login-bdr {
  border: 0px solid #fff !important;
  border-radius: 0px !important;
}

.login-person-1 {
  text-align: center;
  margin-bottom: 20px !important;
  font-size: 18px;
  line-height: 32px;
  color: #132968;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}

.email-mob {
  height: 45px;
}

.mail-img-mob {
  width: 36px;
  height: 36px;
}
.mobile-fn-sz {
  font-size: 38px !important;
}
.login-person-sub {
  text-align: center;
  margin-bottom: 0px !important;
  font-size: 18px;
  line-height: 32px;
  color: #132968;
  letter-spacing: 0;
  font-weight: 700;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .logo img {
    max-width: 51px !important;
    height: 25px !important;
  }
  #admin .logo {
    height: 45px !important;
  }
}
