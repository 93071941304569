.pgFailureWrapper {
  max-width: 1140px;
  padding: 20px 15px;
  margin: 0 auto;
  .ant-card {
    border-radius: 8px;
    box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    .title {
      color: #f0802d;
      font-size: 22px;
      font-weight: 700;
    }
    .subtitle {
      font-weight: 500;
      font-size: 16px;
      .mail {
        color: #f0802d;
        font-weight: 600;
      }
      .tid {
        font-weight: 600;
      }
    }
  }
}
