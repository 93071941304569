@import "../../assets/variableStyles.scss";

.flighttoast {
  .ant-popover-content {
    max-width: 300px;
    .ant-popover-inner-content {
      padding: 8px;
    }
  }
}

.review-bottom-mobile {
  @include mobile {
    order: 1;
  }
}

.review-top-mobile {
  @include mobile {
    order: 0;
  }
}

.passenger-form {
  .form-group {
    @include mobile {
      flex-wrap: nowrap;
    }
  }
}
.checkout-container {
  .pax-heading {
    width: 100%;
  }
}
