@import "../../../assets/variableStyles.scss";
.businvoice {
    .bus-invoice {
        background-color: #eaebee;
        padding: 30px 0;
        .bus-invoice-container {
            max-width: 1280px;
            margin: auto;
            .bus-invoice-row {
                display: flex;
                flex-wrap: wrap-reverse;
                @include mobile {
                    padding: 0 15px;
                }
                .bus-invoice-left-block {
                    max-width: 100%;
                    width: 70%;
                    padding-right: 20px;
                    flex-grow: 1;
                    @include mobile {
                        padding-right: 0;
                        width: 100%;
                    }
                    .invoice-bus-details {
                        margin-bottom: 20px;
                        .user-details {
                            margin: 0;
                        }
                    }
                }
                .bus-invoice-right-block {
                    max-width: 100%;
                    width: 30%;
                    flex-grow: 1;
                    @include mobile {
                        width: 100%;
                    }
                    .invoice-faredetails {
                        position: sticky;
                        top: 0;
                    }
                }
            }
            .form-body-contact-continue {
                padding: 0;
                margin-top: 20px;
                @include mobile {
                    padding: 0 15px;
                }
            }
        }
    }
}
/*************** Booking-Details *************/
.booking-details {
    margin-top: 30px;
    .booking-details-container {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
        .pnr-details {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            span {
            font-size: 14px;
            }
        }
        .ticket-details {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            span {
                font-size: 14px;
                }
        }
        .seat-details {
            display: flex;
            justify-content: space-between;
            span {
                font-size: 14px;
                }
        }
    }
} 
/********** Seat seller and responsibilities terms and conditions ***********/
.seat-seller-responsibilities-container {
    .seat-seller-responsibilities-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px 0;
        .responsible-for {
            @include mobile {
                width: 100%;
                padding: 0;
            }
            .responsible-for-header {
                font-size: 18px;
            }
            ol {
                padding-left: 15px;
                li {
                    list-style-type: decimal;
                }
            }
        }
        .not-responsible-for {
            @include mobile {
                width: 100%;
                padding: 0;
            }
            .not-responsible-for-header {
                font-size: 18px;
            }
            ol {
                padding-left: 15px;
                margin-bottom: 0;
                li {
                    list-style-type: decimal;
                }
            }
        }
    }
} 
/************* terms-conditions-cancellation-policy-container ***************/
.terms-conditions-cancellation-policy-container {
    .terms-conditions-cancellation-policy-row {
        display: flex;
        flex-wrap: wrap;
        .terms-conditions {
            @include mobile {
                width: 100%;
                padding: 0;
            }
            h4 {
                font-size: 18px;
            }
        }
        .cancellation-policy {
            @include mobile {
                width: 100%;
                padding: 0;
            }
            .cancellation-policy-header {
                font-size: 18px;
            }
            .cancellation-policy-body {
                ul {
                    li {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
} 
.user-details {
    margin: 0;
    @include mobile {
        h3 {
            font-size: 16px !important;
            margin-top: 10px;
        }
    }
}
/***************** Invoice Styles *********************/
.invoice-block {
    padding: 15px;
    margin: auto;
    max-width: 1280px;
    background-color: #fff;
    .invoice-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e7e7e7;
        .invoice-company-logo {
            display: flex;
            align-items: center;
            .invoice-company-img {
                // padding-right: 10px;
                border-right: 1px solid #e7e7e7;
                a {
                    img {
                        height: 50px;
                    }
                }
            }
        }
        .invoice-title {
            font-size: 25px;
            // padding-left: 10px;
        }
        .invoice-trip-contact-details {
            ul {
                text-align: right;
            }
        }
    }
    .invoice-body {
        .invoice-body-first-line {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #e7e7e7;
            .invoice-from-to-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .from-to-names {
                    margin-right: 20px;
                    ul {
                        display: flex;
                        margin-bottom: 0;
                        li {
                            margin-right: 10px;
                            font-size: 16px;
                            font-weight: 900;
                        }
                    }
                }
                .invoice-date-time {
                    font-size: 16px;
                }
            }
            .invoiceticket-number {
                ul {
                    margin-bottom: 0;
                    text-align: right;
                    li {
                        &:nth-child(1) {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .invoice-second-line {
            padding: 10px 0;
            border-bottom: 1px solid #e7e7e7;
            ul {
                display: flex;
                margin-bottom: 0;
                li {
                    width: 25%;
                    div {
                        &:nth-child(1) {
                            font-size: 14px;
                            font-weight: 600;
                        }
                        &:nth-child(2) {
                            color: #757575;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .invoice-third-line {
            padding: 10px 0;
            border-bottom: 1px solid #e7e7e7;
            ul {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                li {
                    width: 25%;
                    div {
                        &:nth-child(1) {
                            font-size: 14px;
                            font-weight: 600;
                        }
                        &:nth-child(2) {
                            color: #757575;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .invoice-fourth-line {
            padding: 10px 0;
            border-bottom: 1px solid #e7e7e7;
            ul {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                li {
                    width: 25%;
                    div {
                        &:nth-child(1) {
                            font-size: 14px;
                            font-weight: 600;
                        }
                        &:nth-child(2) {
                            color: #757575;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .invoice-fifth-line {
            padding: 10px 0;
            border-bottom: 1px solid #e7e7e7;
            ul {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                li {
                    width: 50%;
                    div {
                        &:nth-child(1) {
                            color: #757575;
                            font-size: 11px;
                        }
                        &:nth-child(2) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .invoice-note {
            // margin-bottom: 15px;
            .note-indication {
                font-size: 11px;
            }
            .total-fare {
                text-align: right;
                .invoice-price {
                    font-size: 14px;
                    font-weight: 600;
                }
                .invoice-tax-info {
                    color: #757575;
                    font-size: 11px;
                }
            }
        }
    }
    .invoice-terms-conditions {
        position: relative;
        .invoice-terms-header {
            position: absolute;
            left: 42%;
            top: -20px;
            background: white;
            width: 235px;
            h5 {
                text-align: center;
                margin: 10px 0;
                font-size: 14px;
                font-weight: 600;
            }
        }
        .invoice-content {
            display: flex;
            padding-top: 15px;
            justify-content: space-between;
            border-top: 2px solid black;
            div {
                width: 100%;
                &:nth-child(2) {
                    table {
                        display: inline;
                        border: none;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}