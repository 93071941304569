// @import "../../../../assets/variableStyles.scss";
// .results-header-block {
//   background-color: #00164d;
//   height: 100%;
//   width: 100%;
//   margin-top: -67px;
//   .bread-crumb-block {
//     padding-top: 67px;
//     ul.breadcrumb {
//       padding: 10px 16px;
//       list-style: none;
//       background-color: transparent;
//     }
//     ul.breadcrumb li {
//       display: inline;
//       font-size: 14px;
//     }
//     ul.breadcrumb li + li:before {
//       padding: 8px;
//       color: #dcdee3;
//       content: "/\00a0";
//     }
//     ul.breadcrumb li a {
//       color: #dcdee3;
//       text-decoration: none;
//     }
//     ul.breadcrumb li a:hover {
//       color: #dcdee3;
//       text-decoration: underline;
//     }
//   }
//   .bus-results-text {
//     h2 {
//       color: #fff;
//       font-size: 34px;
//       text-align: center;
//       margin-bottom: 0;
//       @include mobile {
//         font-size: 18px;
//       }
//       .bus-results-to-text {
//         font-weight: 700;
//       }
//     }
//   }
//   .trip-selector {
//     width: 150px;
//     .tripselector-form {
//       margin-bottom: 0;
//       padding: 0 15px 0 5px;
//       background-color: transparent;
//     }
//     .form-selection-select {
//       background-color: transparent;
//       color: #fff;
//       border: none;
//       cursor: pointer;
//       &:focus {
//         box-shadow: none;
//       }
//       option {
//         color: #1c2b39;
//       }
//     }
//   }
//   .search-modify-block {
//     padding-bottom: 20px;
//   }
//   .search-modify-block {
//     .flight_search_box {
//       background-color: transparent;
//       padding: 0 20px;
//       margin-top: 0;
//       .flight-header {
//         display: none;
//       }
//       .custom-column {
//         label {
//           color: #bcd5f5;
//         }
//       }
//       .flight-search-btn {
//         background-color: #e2076a;
//       }
//     }
//   }
// }
// .results-body-block {
//   background-color: #eaebee;
//   .results-container-fluid {
//     max-width: 1280px;
//     padding: 20px 12px 15px;
//     margin: auto;
//     @include mobile {
//       padding: 20px 15px 15px;
//     }
//   }
// }
// /********** Responsive styles *********/
// .responsive-header-block {
//   background-color: #002172;
//   padding: 15px;
//   @include mobile {
//     margin-top: 41px;
//   }
//   .responsive-header-block-container-fluid {
//     .responsive-header-block-row {
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;
//       justify-content: space-between;
//       .responsive-from-header {
//         padding-right: 7px;
//         p {
//           margin-bottom: 0;
//           color: #fff;
//           font-size: 13px;
//           font-weight: 600;
//         }
//       }
//       .responsive-icons {
//         padding-right: 7px;
//         i {
//           color: #fff;
//         }
//       }
//       .responsive-to-header {
//         padding-right: 7px;
//         p {
//           margin-bottom: 0;
//           color: #fff;
//           font-size: 13px;
//           font-weight: 600;
//         }
//       }
//       .responsive-onward {
//         padding-right: 7px;
//         p {
//           margin-bottom: 0;
//           color: #fff;
//           font-size: 13px;
//           font-weight: 600;
//         }
//       }
//       .responsive-return {
//         padding-right: 7px;
//         p {
//           margin-bottom: 0;
//           color: #fff;
//           font-size: 13px;
//           font-weight: 600;
//         }
//       }
//       .responsive-modify-btn {
//         button {
//           background-color: #fff;
//           border: none;
//           border-radius: 5px;
//           color: #00164d;
//           font-size: 12px;
//           padding: 8px;
//         }
//       }
//     }
//   }
// }
// /****** Responsive Filters **********/
// .sticky-filter {
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   z-index: 1;
//   background-color: #00164d;
//   .sticky-filter-container {
//     padding: 15px;
//     .sticky-filter-row {
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// }

// /********** Styling checkbox ***********/
// .ac-filter-container {
//   margin-bottom: 0;
//   input {
//     opacity: 0;
//     position: absolute;
//   }
//   span {
//     p {
//       margin-bottom: 0;
//       color: #fff;
//       cursor: pointer;
//       i {
//         display: block;
//         text-align: center;
//       }
//     }
//   }
//   input:checked ~ .checkmark p {
//     color: #e2076a;
//   }
// }
// /************** Responsive Sort and Filters Styles ************/
// .responsive-sort-filters {
//   .sort-filters-container {
//     background-color: #002172;
//     padding: 20px 15px;
//     .sort-filters-heading {
//       h4 {
//         margin-bottom: 0;
//         font-size: 15px;
//         display: flex;
//         align-items: center;
//         color: #fff;
//         i {
//           margin-right: 10px;
//           font-size: 20px;
//         }
//       }
//     }
//   }
//   .sort-filters-tab-container {
//     .nav-tabs {
//       a {
//         border: none;
//         width: 50%;
//         text-align: center;
//         color: #000;
//         border-bottom: 2px solid #ccc;
//         padding: 15px;
//         font-size: 13px;
//         text-transform: uppercase;
//         font-weight: 600;
//       }
//       .active {
//         border-bottom: 2px solid #096dd9;
//         color: #096dd9;
//       }
//     }
//   }
//   .sort-tab-content {
//     ul {
//       padding: 30px 15px;
//       li {
//         margin-bottom: 15px;
//         input {
//           cursor: pointer;
//         }
//         label {
//           cursor: pointer;
//         }
//       }
//     }
//   }
//   .done-btn {
//     button {
//       background-color: #002172;
//       color: #fff;
//       font-size: 13px;
//       font-weight: 600;
//       padding: 15px;
//       text-align: center;
//       border: none;
//       width: 100%;
//     }
//   }
// }
// /************** Responsive Boarding-Dropping Points Styles ************/
// .responsive-boarding-dropping-points {
//   .responsive-boarding-dropping-points-container {
//     .responsive-boarding-dropping-points-closer {
//       padding: 15px 15px 0;
//       p {
//         margin-bottom: 0;
//         text-align: end;
//         i {
//           font-size: 20px;
//         }
//       }
//     }
//   }
//   .responsive-boarding-dropping-points-tab-container {
//     .nav-tabs {
//       a {
//         border: none;
//         width: 50%;
//         text-align: center;
//         color: #000;
//         border-bottom: 2px solid #ccc;
//         padding: 15px;
//         font-size: 13px;
//         text-transform: uppercase;
//         font-weight: 600;
//       }
//       .active {
//         border-bottom: 2px solid #096dd9;
//         color: #096dd9;
//       }
//     }
//     .boardingpoint-tab-content,
//     .droppingpoint-tab-content {
//       ul {
//         padding: 15px;
//         li {
//           display: flex;
//           margin-bottom: 10px;
//           .boarding-point-checkbox,
//           .dropping-point-checkbox {
//             margin-right: 15px;
//             width: 10%;
//           }
//           .boarding-point-address,
//           .dropping-point-address {
//             width: 70%;
//             h5 {
//               margin-bottom: 0;
//               font-size: 14px;
//               font-weight: 600;
//             }
//             p {
//               margin-bottom: 0;
//               font-size: 14px;
//             }
//           }
//           .boarding-point-time,
//           .dropping-point-time {
//             width: 20%;
//           }
//         }
//       }
//     }
//     .continue-block {
//       padding: 15px;
//       background-color: #fff;
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       box-shadow: 0 -2px 2px #dcdee3;
//       .price-container {
//         display: flex;
//         p {
//           margin-bottom: 0;
//           font-size: 16px;
//           font-weight: 600;
//         }
//       }
//       .continue-btn {
//         button {
//           background-color: #002172;
//           color: #fff;
//           font-weight: 600;
//           border: none;
//           border-radius: 5px;
//           padding: 10px 15px;
//         }
//       }
//     }
//   }
// }
// /*************** Responsive Seat Selection ***************/
// .seat-selection-container {
//   .travels-name-container {
//     .travels-heading {
//       padding: 15px;
//       background-color: #002172;
//       display: flex;
//       .travels-back-icon {
//         margin-right: 15px;
//         i {
//           font-size: 20px;
//           color: #fff;
//         }
//       }
//     }
//     .travels-name-text {
//       h5 {
//         font-size: 14px;
//         font-weight: 600;
//         color: #fff;
//         margin-bottom: 0;
//       }
//       p {
//         font-size: 13px;
//         color: #fff;
//         margin-bottom: 0;
//       }
//     }
//   }
//   .travels-tab-container {
//     .lower-seater-tab-content {
//       .tab-content {
//         height: 70vh;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }
//     .nav-tabs {
//       background-color: #002172;
//       a {
//         border: none;
//         width: 33.3%;
//         text-align: center;
//         color: #ccc;
//         // border-bottom: 2px solid #ccc;
//         padding: 15px;
//         font-size: 13px;
//         text-transform: uppercase;
//         font-weight: 600;
//       }
//       .active {
//         // border-bottom: 2px solid #096dd9;
//         color: #fff;
//         background-color: transparent;
//       }
//     }
//     .seat-layout-header {
//       justify-content: center;
//     }
//     .tab-pills-header {
//       justify-content: center;
//     }
//     .travels-tab-continue-block {
//       padding: 15px;
//       background-color: #fff;
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       box-shadow: 0 -2px 2px #dcdee3;
//       .show-price-responsive {
//         display: flex;
//       }
//       .collapsed-text {
//         padding: 15px;
//       }
//       .price-container {
//         display: flex;
//         p {
//           margin-bottom: 0;
//           font-size: 16px;
//           font-weight: 600;
//           &:nth-child(2) {
//             margin-left: 10px;
//           }
//         }
//       }

//       .seat-no {
//         span {
//           font-size: 14px;
//           font-weight: 600;
//           color: #e2076a;
//         }
//       }
//       .continue-btn {
//         button {
//           background-color: #002172;
//           color: #fff;
//           font-weight: 600;
//           border: none;
//           border-radius: 5px;
//           padding: 10px 15px;
//         }
//       }
//     }
//   }
// }
// @include mobile {
//   .amenities-tab {
//     padding: 15px;
//     .aminities-tab-header {
//       h4 {
//         font-size: 16px;
//         font-weight: 600;
//       }
//     }
//     .aminities-tab-body {
//       ul {
//         margin-bottom: 0;
//         li {
//           // display: flex;
//           p {
//             width: 100%;
//             // margin-right: 70px;
//             padding: 10px;
//             margin-bottom: 5px;
//             border-bottom: 1px solid #969dac;
//             font-size: 14px;
//             i {
//               margin-right: 15px;
//               color: #969dac;
//               font-size: 20px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .cancellation-block {
//     padding: 15px;
//     .cancellation-fee {
//       border: 1px solid rgba(33, 150, 243, 0.46);
//       border-radius: 5px;
//     }
//     .cancellation-header {
//       background: #0775e2;
//       padding: 5px 10px;
//       h5 {
//         font-size: 15px;
//         font-weight: 600;
//         color: #fff;
//         i {
//           margin-right: 10px;
//           font-size: 20px;
//         }
//       }
//     }
//     .cancellation-body {
//       li {
//         display: flex;
//         justify-content: space-between;
//         padding: 5px;
//         border-bottom: 1px solid #0775e2;
//         &:nth-child(1) {
//           font-weight: 600;
//           font-size: 14px;
//           color: #000;
//         }
//         &:last-child {
//           border-bottom: none;
//         }
//       }
//     }
//   }
// }
// // ******* seat layout ******//
// .seat-layout-outer {
//   margin: 15px;
//   border: 1px solid #1c2b39;
//   padding: 15px;
//   border-left: 5px solid #1c2b39;
//   display: flex;
//   @include mobile {
//     transform: rotate(90deg);
//     height: 25vh;
//   }
//   .seat-layout-title-block {
//     .seat-layout-title-block-inner-data {
//       display: flex;
//       flex-direction: column;
//       height: 100%;
//       align-items: center;
//       border-right: 1px solid #1c2b39;
//       margin-right: 15px;
//       justify-content: space-between;
//     }
//     .seat-layout-title-text {
//       p {
//         margin-bottom: 15px;
//         transform: rotate(270deg);
//       }
//     }
//   }
//   .seater-seating-arrangement {
//     height: 100%;
//     width: 100%;
//     @include mobile {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//     }
//     .inner-seating-container {
//       width: 100%;
//       ul {
//         display: flex;
//         li {
//           margin-right: 5px;
//           .seat-layout-container {
//             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
//             height: 18px;
//             width: 19px;
//             border-radius: 0.3rem;
//           }
//         }
//       }
//     }
//     .inner-sleeper-container {
//       display: flex;
//       justify-content: start;
//       ul {
//         display: flex;
//         li {
//           margin-right: 10px;
//           .sleeper-seat {
//             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px
//               0;
//             height: 19px;
//             width: 42px;
//             border-radius: 0.3rem;
//           }
//         }
//       }
//     }
//   }
// }
// .sleeper-layout-outer {
//   margin: 15px;
//   border: 1px solid #1c2b39;
//   padding: 15px;
//   border-left: 5px solid #1c2b39;
//   display: flex;
//   @include mobile {
//     transform: rotate(90deg);
//     height: 25vh;
//   }
//   .seat-layout-title-block {
//     .seat-layout-title-block-inner-data {
//       display: flex;
//       flex-direction: column;
//       height: 100%;
//       justify-content: flex-end;
//       border-right: 1px solid #1c2b39;
//       margin-right: 15px;
//     }
//     .seat-layout-title-text {
//       p {
//         margin-bottom: 15px;
//         transform: rotate(270deg);
//       }
//     }
//   }
//   .seater-seating-arrangement {
//     height: 100%;
//     width: 100%;
//     margin-left: 50px;
//     @include mobile {
//       margin-left: 0;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//     }
//     .inner-seating-container {
//       width: 100%;
//       ul {
//         display: flex;
//         li {
//           margin-right: 5px;
//           .seat-layout-container {
//             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
//             height: 18px;
//             width: 19px;
//             border-radius: 0.3rem;
//           }
//         }
//       }
//     }
//     .inner-sleeper-container {
//       ul {
//         display: flex;
//         li {
//           margin-right: 10px;
//           .sleeper-seat {
//             background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px
//               0;
//             height: 19px;
//             width: 42px;
//             border-radius: 0.3rem;
//           }
//         }
//       }
//     }
//   }
// }
// /******* Responsive price ***********/
// .responsive-price-list {
//   li {
//     display: flex;
//     justify-content: space-between;
//   }
// }
// .collapsed-data {
//   padding: 0 15px;
// }
// .collapse-icon {
//   i {
//     font-size: 20px;
//     margin-right: 5px;
//   }
// }

// /***** Modify Search Styles ****/
// .bus-results-container {
//   .container-block {
//     max-width: 1280px;
//     margin: auto;
//     padding: 75px 15px 0px;
//   }
//   .hotels_search_box {
//     background: transparent;
//     padding: 0;
//     .ant-radio-group {
//       display: none;
//     }
//     .ant-form-item {
//       flex-direction: column;
//     }
//     .ant-form-item-label {
//       label {
//         color: #bcd5f5 !important;
//       }
//     }
//     .ant-form-item-control {
//       width: 100%;
//     }
//     .search-modify-block {
//       padding-bottom: 0;
//     }
//   }
//   .tripselector-form {
//     padding-left: 0;
//   }
//   .form-selection-select {
//     padding-left: 0;
//   }
//   .form-body-room-content {
//     margin-top: 0;
//   }
// }
// /********* Skeleton Styles ******/
// .skeleton-bus-results-card {
//   background-color: #fff;
//   padding: 15px;
//   margin-bottom: 20px;
//   border: 1px solid rgba(0, 0, 0, 0.03);
//   box-shadow: 0px 0px 2px 0px #969dac;
//   .skeleton-travels-name {
//     border-right: 1px solid #e8e8e8;
//     .ant-skeleton-title {
//       margin-top: 0;
//     }
//     .ant-skeleton-paragraph {
//       li {
//         width: 80% !important;
//       }
//     }
//   }
//   .skeleton-timings {
//     .ant-skeleton-title {
//       margin: 0 0 16px auto;
//     }
//     .ant-skeleton-paragraph {
//       li {
//         &:nth-child(1) {
//           width: 20% !important;
//           margin-left: auto;
//         }
//         &:nth-child(2) {
//           width: 38% !important;
//           margin-left: auto;
//         }
//       }
//     }
//   }
//   .skeleton-boarding-address {
//     border-right: 1px solid #e8e8e8;
//     .ant-skeleton-title {
//       margin-top: 0;
//     }
//     .ant-skeleton-paragraph {
//       li {
//         width: 30% !important;
//       }
//     }
//   }
//   .skeleton-bus-price {
//     .ant-skeleton-title {
//       margin: 0 0 5px auto;
//       width: 60% !important;
//     }
//     .ant-skeleton-paragraph {
//       li {
//         &:nth-child(1) {
//           margin-left: auto;
//           width: 25% !important;
//         }
//         &:nth-child(2) {
//           margin-left: auto;
//           margin-top: 30px !important;
//           width: 38% !important;
//         }
//       }
//     }
//   }
//   .ant-divider {
//     margin: 10px 0;
//   }
//   .skeleton-bus-tabs {
//     .ant-skeleton-title {
//       width: 100% !important;
//       margin-top: 10px;
//       margin-bottom: 0;
//     }
//   }
// }
// .edit-onward {
//   margin-bottom: 15px;
//   .ant-card-body {
//     padding: 15px;
//   }
//   .onward-bus-name {
//     font-size: 18px;
//     color: #05004e !important;
//   }
//   .onward-bus-type {
//     font-size: 14px;
//     margin-bottom: 0;
//   }
//   .onward-boarding-point {
//     margin-bottom: 0;
//   }
//   .edit-timimg-list {
//     display: flex;
//     margin-bottom: 8px !important;
//     p {
//       margin-bottom: 0;
//     }
//   }
//   .onward-price {
//     li {
//       &:nth-child(1) {
//         margin-bottom: 8px !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 768px) {
//   #myModalbus .modal.left .modal-dialog {
//     position: fixed;
//     margin: auto;
//     width: 320px;
//     height: 100%;
//     -webkit-transform: translate3d(0%, 0, 0);
//     -ms-transform: translate3d(0%, 0, 0);
//     -o-transform: translate3d(0%, 0, 0);
//     transform: translate3d(0%, 0, 0);
//   }

//   #myModalbus .modal.left .modal-content {
//     height: 100%;
//     overflow-y: auto;
//   }

//   #myModalbus .modal.left .modal-body {
//     padding: 15px 15px 80px;
//   }

//   /*Left*/
//   #myModalbus .modal.left.fade .modal-dialog {
//     -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
//     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
//     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
//     transition: opacity 0.3s linear, left 0.3s ease-out;
//   }

//   #myModalbus .modal.left.fade.in .modal-dialog {
//     left: 0;
//   }
// }

// .buses-sort-price li {
//   list-style: none;
//   padding-bottom: 10px;
// }
// .buses-sort-price li a {
//   font-size: 15px;
//   font-weight: bold;
// }
// /*bus--sec--info--*/
// .skeleton-bus-panel {
//   padding: 5px 5px;
// }
.sort-modal-mobiletr {
  .ant-modal-content {
    .ant-modal-body {
      .sort-block {
        @media screen and (max-width: 768px) {
          display: block !important;
        }
      }
    }
  }
}
.collapse-modify-search{
  border: 2px solid transparent;
  width: 89%;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .collapse-modify-search{
    width: 99%;
  }
}