@import "../../assets/variableStyles.scss";

#footer {
  background-color: #1b1b1b;
  color: #f5f5f5;
  padding: 30px 0;
  font-family: 'Arial', sans-serif;

  // margin-top: 20px;
  // @include mobile {
  //   margin-top: -60px;
  // }


  .footer_t_section {
    // text-align: center;
    // padding-bottom: 20px;

    .footer_social_info {
      display: flex;
      justify-content: space-around;
      // gap: 15px;

      .social_icon {
        font-size: 24px;
        color: #0188cc;
        transition: color 0.3s;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  .footer_bottom_section {
    .footer_container {
      display: flex;
      justify-content: space-between;
      align-items: start;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;

      @include tab {
        flex-direction: row;
      }

      .footer_links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        .footer_column {
          flex: 1;
          min-width: 200px;

          p {
            font-weight: bold;
            margin-bottom: 10px;

            @include mobile {
              display: flex;
            }

            @include tab {
              display: flex;
            }
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 8px;

              a {
                color: #f5f5f5;
                text-decoration: none;
                transition: color 0.3s;

                &:hover {
                  color: #0073e6;
                }
              }

              i {
                margin-right: 10px;
              }

              @include mobile {
                // font-size: 12px;
                display: flex;
                justify-content: flex-start;
              }

              @include tab {
                // font-size: 12px;
                display: flex;
                justify-content: flex-start;
              }
            }

          }

          @include mobile {
            min-width: 140px;
          }
        }
      }

      .footer_copyright {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        // .i2s{
        // filter: invert(1) sepia(1) saturate(10) hue-rotate(190deg);
        // }

        span {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .footer_bottom_section .footer_container {
      flex-direction: column;
      align-items: center;

      .footer_links {
        justify-content: center;
        text-align: center;
      }

      .footer_copyright {
        margin-top: 40px;
      }
    }
  }

}

.comp-add {
  margin: 40px 11% 10px;
}