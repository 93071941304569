.disc-ban {
    background-image: url("../../assets/images/disclaimer-new.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    margin-bottom: 50px;
}

.disc-policy {
    margin: 0 auto;
    width: 1100px;

    h2 {
        display: flex;
        justify-content: center;
        font-weight: 600;
    }

    .cont-inf {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        /* Adjust the width as needed */
        margin: 0 auto;
        margin-bottom: 20px;
        
    }

    .contimg {
        flex: 0 0 45%;
        /* Image container takes 45% of the width */
    }

    .contimg img {
        max-width: 100%;
        height: auto;
        display: block;
    }

    .contpara {
        flex: 0 0 50%;
        /* Text container takes 50% of the width */
        text-align: left;
        border-bottom: 2px solid #a0a0a04a;
        padding: 10px;
    }

    .contpara h6 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .contpara p {
        font-size: 16px;
        line-height: 1.5;
        color: #333;
    }

}