@import "../../assets/variableStyles.scss";

.combined-banner {
  .menu-items {
    li {
      color: #fff;
    }
  }
  .carousel-images {
    max-height: 100%;
    .image-container {
      position: relative;
      h4 {
        color: #fff;
        position: absolute;
        top: 95px;
        left: 0;
        right: 0;
        font-size: 30px;
        font-weight: 600;

        text-align: center;

        @include mobile {
          font-size: 17px;

          top: 50px;
        }
      }
    }
    img {
      width: 100%;
      height: 350px;
    }
  }
  .slick-list {
    height: 28em;
    @include mobile {
      height: 100%;
    }
  }
}
