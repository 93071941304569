//  ********* Media Queries For Desktop  ************

$for-desktop: 1200px;

@mixin desktop {
  @media screen and (max-width: #{ $for-desktop }) {
    @content;
  }
}

//  ********* Media Queries For Tab  ************

$tab: 1024px;

@mixin tab {
  @media screen and (max-width: #{ $tab }) {
    @content;
  }
}

//  ********* Media Queries For Mobile  ************

$mobile: 767px;

@mixin mobile {
  @media screen and (max-width: #{ $mobile }) {
    @content;
  }
}

$xsmobile: 350px;

@mixin xsmobile {
  @media screen and (max-width: #{ $xsmobile }) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
