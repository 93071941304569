.refundpolicy-new {
    background-image: url("../../assets/images/policy-new.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
    width: 100%;
    margin-bottom: 50px;
}

.ref-para {
    // margin-top: 10px;
    ul {
        li {
            margin-bottom: 10px;
            // font-size: 14px;

        }
    }
}

.ref-terms-po {

    background: mintcream;
    padding: 20px;
    box-shadow: 2px 3px 8px grey;
    margin-bottom: 20px;
    background: mintcream;
    padding: 20px;
    box-shadow: 2px 3px 8px grey;
    margin-bottom: 20px;
}