
@import "../../assets/variableStyles.scss";

.flightInvoice {
    max-width: 1280px;
    .flight-invoice-body {
        padding: 15px 0px;
        .invoice-booking-details {
            .booking-heading {
                display: flex;
                justify-content: space-between;
                color: #0775e2;
                h5 {
                    color: #0775e2;
                }
            }
            .booking-details {
                @include mobile {
                    margin-top: 10px;
                }
                .booking-details-fields {
                    display: flex;
                    @include mobile {
                        justify-content: space-between;
                    }
                    label {
                        font-weight: 900;
                        margin-right: 10px;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .sub-headings {
                background: #eaeaea;
                padding: 10px 18px;
                display: flex;
                align-items: center;
                h5 {
                    color: #0775e2;
                    margin-bottom: 0px;
                    font-weight: 600;
                }
            }
            .cancellation-details {
                padding: 20px 0px;
                .cancellation-fields {
                    label {
                        font-weight: 900;
                    }
                }
            }
            .flight-details {
                padding: 20px 0px;
                .flight-left-section {
                    .airline-img {
                        img {
                            width: 100px;
                            height: 50px;
                        }
                    }
                }
                .airline-name {
                    margin: 12px 0px;
                }
                .flight-more-details {
                    p {
                        margin-bottom: 0px !important;
                    }
                }
            }
            .holder-details {
                padding: 20px 0px;
                .holder-fields {
                    display: flex;
                    justify-content: space-between;
                    label {
                        font-weight: 900;
                    }
                }
            }
            .booking-notes {
                padding: 20px 30px;
                ul li {
                    list-style-type: disc;
                    padding: 5px 0px;
                }
            }
            .pax-table {
                padding: 20px 7px;
                p {
                    color: #0775e2;
                }
                table {
                    th {
                        border: solid 1px #fff;
                        background: #0775e2;
                        color: #fff;
                    }
                    td {
                        border: solid 1px #ccc;
                    }
                }
            }
        }
    }
}