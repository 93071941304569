@import "../../../../assets/variableStyles.scss";

.results-card-block {
  .results-sort-block {
    // display: flex;
    // align-items: center;
    //  padding: 20px;
    // flex-wrap: wrap;
    // background-color: rgba(0, 0, 0, 0.03) !important;
    // border-radius: 8px;
    // box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    // border: 0px solid #ffffff !important;
    // margin-bottom: 20px;

    // .sort-header {
    //   // color: #505c75;
    //   // font-size: 12px;
    //   // font-weight: 600;
    //   // margin-bottom: 0;
    //   // margin-right: 10px;
    // }

    .sort-body {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      li {
        margin: 0 10px;

        a {
          font-size: 15px;
          color: #969dac;

          @include mobile {
            font-size: 12px;
          }
        }
      }

      .active {

        a,
        span {
          color: #1c2b39;
          border-top: 2px solid #0775e2;
          padding: 5px 0;
          cursor: pointer;
        }
      }
    }
  }

  .card-results-block {

    // width: 100%;
    // height: 100%;
    // padding-right: 2px;
    // overflow: hidden;
    // ::-webkit-scrollbar {
    //   display: none;
    // }
    // .resultsScrollWrapper {
    //   width: 100%;
    //   height: 100%;
    //   overflow-y: scroll;
    //   padding-right: 18px;
    //   box-sizing: content-box;
    //   max-height: 630px;
    // }
    .edit-onward {
      margin-bottom: 15px;

      .returntext {
        color: #f0802d;
        font-weight: 600;
      }

      .ant-card-body {
        padding: 15px;
      }

      .box_area {
        display: flex;

        .onward_wrapper {
          padding-right: 4px;
          border-right: 2px solid #eee;
          color: #f0802d;

          p {
            font-weight: 600;
            margin-bottom: 3px;
            font-size: 14px;
          }
        }

        .bus-details {
          padding-left: 4px;
        }
      }

      .onward-bus-name {
        font-size: 16px;
        color: #0775e2 !important;
        margin-bottom: 3px;
        font-weight: 600;
      }

      .onward-bus-type,
      .onward-boarding-point,
      .edit-timimg-list {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: 600;
        color: #969dac;
      }

      .onward-price {
        li {
          font-weight: 600;

          &:nth-child(2) {
            font-size: 12px;
            font-weight: 600;
            color: #969dac;
          }
        }
      }

      .tg-primary-btn {
        width: auto;
        font-size: 12px;
      }
    }

    .card-results {
      margin-bottom: 16px;

      border-radius: 8px !important;
      border: 0px;
      background-color: rgb(255, 255, 255);

      box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    }

    .card-results-travel-details {
      padding: 14px;


      .card-results-row {
        .card-results-columns {
          border-right: 0px solid #969dac;

          @include mobile {
            border: none;
          }

          .travels-name {
            h2 {
              color: #0775e2;
              font-size: 18px;

              @include mobile {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }

          .selected-bus-type {
            font-size: 11px;
            color: #969dac;
            margin-bottom: 0;
            font-weight: 400;
          }

          &:last-child {
            border-right: none;
          }
        }

        .from-to-details {
          display: block;

          .bus-timings {
            ul {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .du-fast {
                border-top: 2px solid #0775e2;
                margin: 0 5px;
                padding-bottom: 5px;
                position: relative;
                flex: 1;
                color: #555;
                font-size: 10px;
                font-weight: 600;

                span {
                  position: absolute;
                  top: -7px;
                  left: 0;
                  right: 0;
                  width: fit-content;
                  margin: 0 auto;
                  background: white;
                  padding: 0 5px;
                }
              }

              li {
                margin-bottom: 5px;
                text-align: start;
                display: inline-block;
                color: #1c2b39;
                font-size: 18px;
                font-weight: 700;

                @include mobile {
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }

            .arrivaldate {
              font-weight: 600;
              text-align: right;
              color: #f0802d;
            }
          }

          .address {
            width: 100%;
            margin-right: 15px;
            display: block;

            @include mobile {
              display: block;
            }

            p {
              margin-bottom: 5px;
            }

            .onboard-address {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }

            .available-boardingpoints {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;

              @include mobile {
                padding-bottom: 10px;
                padding-left: 0%;
              }
            }
          }
        }

        .available-price-details {
          text-align: right;

          @include mobile {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #969dac;
            padding-top: 10px;
            align-items: center;
          }

          .starting-price {
            margin-bottom: 5px;

            @include mobile {
              margin-bottom: 0;
              text-align: start;
              flex: 1;
            }

            .starting-price-text {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }

            .starting-price-number {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 0;

              @include mobile {
                font-size: 16px;
              }

              // .db {
              //   display: block;
              //   @include mobile {
              //     display: inline;
              //   }
              // }
            }
          }

          .no-of-seats {
            span {
              font-size: 11px;
              color: #969dac;
              margin-bottom: 0;
              font-weight: 400;
            }
          }
        }
      }
    }

    .card-results-tabs-info {
      .cards-tabs-block {
        .nav-tabs {
          // background-color: rgba(0, 0, 0, 0.03);
          background-color: #b4b4b438;
          justify-content: flex-start;
          margin: 0;

          @include mobile {
            justify-content: start;
          }

          .tab-content {
            .container-fluid {
              padding: 0;
            }
          }

          .nav-link {
            color: #1c2b39;
            border: none;
            font-size: 12px;
            font-weight: 600;

            @include mobile {
              display: inline-flex;
              font-size: 12px;
            }

            &:hover {
              border: none;
            }

            &:last-child {
              background-color: #f0802d;
              color: #fff;
              border-radius: 0;
              font-weight: 600;
              display: block;
              margin-left: auto;
              border-radius: 4px;

              &:active {
                background-color: #f0802d;
                color: #fff;
                border-bottom: none;
              }
            }
          }

          .nav-link.active {
            color: #f0802d;
            border-color: transparent;
            background-color: transparent;
            border-bottom: 1px solid #f0802d;

            &:last-child {
              background-color: #f0802d;
              color: #fff;
              border-bottom: none;
            }

            @include mobile {
              border-bottom: none;
            }
          }
        }

        .amenities-tab {
          padding: 15px;

          .aminities-tab-header {
            h4 {
              font-size: 16px;
              font-weight: 600;
            }
          }

          .aminities-tab-body {
            ul {
              margin-bottom: 0;

              li {
                display: flex;

                p {
                  width: 50%;
                  margin-right: 70px;
                  padding: 10px;
                  margin-bottom: 5px;
                  border-bottom: 1px solid #969dac;
                  font-size: 14px;
                  font-weight: 700;

                  i {
                    margin-right: 15px;
                    color: #969dac;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }

        .boarding-dropping-points-tab {
          padding: 0px;

          .close-btn {
            text-align: right;
            padding-bottom: 10px;

            span {
              margin-right: 5px;
            }
          }

          .boarding-dropping-points-tab-container {
            border: 2px solid #ffffff;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;

            @include mobile {
              flex-direction: column;
            }

            .boarding-points-block {
              width: 95%;
              border-right: 2px solid #f7f7f7;
              margin-top: -3%;

              @include mobile {
                width: 100%;
                border-right: none;
              }
            }

            .boarding-points-block {
              width: 50%;
              border-right: 2px solid #f7f7f7;
              margin-top: -3%;

              @include mobile {
                width: 100%;
                border-right: none;
              }

              ::-webkit-scrollbar {
                width: 4px;
              }

              ::-webkit-scrollbar-track {
                background-color: #f7f7f7;
                border-radius: 16px;
              }

              ::-webkit-scrollbar-thumb {
                background-color: #d8d8d8;
                border-radius: 16px;
              }

              ::-webkit-scrollbar-button {
                display: none;
              }

              .boarding-points-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                  color: #000000;
                }
              }


              .boarding-points-block-body {
                max-height: 200px;
                overflow-y: scroll;

                @include mobile {
                  max-height: 140px;
                }

                ul {
                  margin-bottom: 0;

                  li {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .dot {
                      width: 5px;
                      height: 5px;
                      border: 1px solid #000000;
                      display: inline-block;
                      border-radius: 50%;
                      margin-right: 15px;
                    }

                    span {

                      font-size: 10px;
                      font-weight: 600;
                      color: #000000;
                      border: 2px solid transparent;

                      // .pointTooltip {
                      //   overflow: hidden;
                      //   text-overflow: ellipsis;
                      //   white-space: nowrap;
                      // }
                      &:nth-child(2) {
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }

            .dropping-points-block {
              width: 45%;
              margin-top: -3%;
              padding: 0px;

              @include mobile {
                width: 100%;
                padding-top: 0;
              }

              ::-webkit-scrollbar {
                width: 4px;
              }

              ::-webkit-scrollbar-track {
                background-color: #f7f7f7;
                border-radius: 16px;
              }

              ::-webkit-scrollbar-thumb {
                background-color: #d8d8d8;
                border-radius: 16px;
              }

              ::-webkit-scrollbar-button {
                display: none;
              }

              .dropping-points-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                  color: #000000;

                  @include mobile {
                    margin-top: 2%;
                  }
                }
              }

              .dropping-points-block-body {
                max-height: 200px;
                overflow-y: scroll;

                @include mobile {
                  max-height: 140px;
                }

                ul {
                  margin-bottom: 0;

                  li {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .dot {
                      width: 5px;
                      height: 5px;
                      border: 1px solid #000000;
                      display: inline-block;
                      border-radius: 50%;
                      margin-right: 15px;
                    }

                    span {
                      font-size: 10px;
                      font-weight: 600;
                      color: #000000;

                      &:nth-child(2) {
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .cancllation-policy-tab {
          padding: 0px;

          .close-btn {
            text-align: right;
            padding-bottom: 0px;

            span {
              margin-right: 5px;
            }
          }

          .cancellation-policy-container {
            border: 2px solid transparent;
            width: 90%;
            margin-top: -2%;
            display: flex;
            flex-wrap: wrap;

            .cancellation-block {
              flex: 1;
              margin: 0;

              .cancellation-block-header {
                h4 {
                  font-size: 12px;
                  font-weight: 600;
                }
              }

              .cancellation-block-body {
                ul {
                  margin-bottom: 0;
                  border: 2px solid #f7f7f7;
                  border-radius: 10px;

                  li {
                    display: flex;
                    justify-content: space-between;

                    span {
                      width: 50%;
                      padding: 0px 0px;
                      border-top: 2px solid #f7f7f7;
                      border-right: 2px solid #f7f7f7;
                      font-size: 12px;
                      font-weight: 500;
                    }

                    .title {
                      border-top: 0;
                      font-weight: 600;
                    }

                    .last {
                      border-right: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .pricefilter_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pricefilter {
            display: flex;
            align-items: center;

            .ant-btn {
              border-radius: 20px;
              height: auto;
              margin-left: 15px;

              @include mobile {
                font-size: 12px;
                margin-left: 5px;
                padding: 2px 8px;
              }

              .seatcount {
                font-size: 12px;
                margin-left: 3px;

                @include mobile {
                  font-size: 10px;
                  margin-left: 3px;
                }
              }
            }

            .active {
              color: #fff;
              background-color: #f0802d;
              border-color: #f0802d;
            }
          }

          .close-btn {
            // padding: 0px 5px;
            // border: 1px solid #555;
            // border-radius: 25px;
            // width: 70px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;

            @include mobile {
              font-size: 14px;
            }

            // i {
            //   margin-left: 10px;
            // }
          }
        }
      }

      .seats_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .boarding-dropping-point-block {
          flex: 1;
          max-width: 450px;
          overflow: visible;

          ::-webkit-scrollbar {
            display: block !important;
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background-color: #f7f7f7;
            border-radius: 16px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
            border-radius: 10px;
          }

          ::-webkit-scrollbar-button {
            display: none;
          }

          @include tab {
            max-width: 100%;
          }
        }

        @include tab {
          flex-direction: column;
        }
      }

      /*********** boarding-dropping-point-block **********/
      .seat-selection-block {
        .boarding-dropping-point-block {
          box-shadow: 0 0 1px rgb(255, 255, 255);
          margin-top: 10px;

          @include tab {
            padding: 0 10px;
          }

          .boarding-dropping-point-block-inner {
            .boarding-dropping-point-block-tabs {
              .ant-form {
                padding: 0px;

                // .ant-form-item {
                //   // margin-bottom: 5px;
                // }

                .ant-select-selection-item {
                  .pointTooltip {
                    white-space: normal !important;
                  }
                }
              }

              .nav-tabs {
                background-color: transparent;
                justify-content: space-between;

                a {
                  width: 50%;
                  border-bottom: 1px solid #505c75;
                  text-align: center;

                  &:last-child {
                    background-color: transparent;
                    color: #000;
                    font-weight: normal;
                  }
                }

                .nav-link.active {
                  color: #f0802d;
                  border-bottom: 2px solid #f0802d;
                }
              }

              .boarding-points-web,
              .dropping-points-web {
                ul {
                  padding: 15px;

                  li {
                    display: flex;
                    margin-bottom: 10px;

                    .boarding-point-checkbox,
                    .dropping-point-checkbox {
                      margin-right: 15px;
                      width: 5%;
                    }

                    .boarding-point-address,
                    .dropping-point-address {
                      width: 75%;

                      h5 {
                        margin-bottom: 0;
                        font-size: 12px;
                        font-family: Calibri;
                        font-weight: 600;
                      }

                      p {
                        margin-bottom: 0;
                        font-size: 12px;
                        display: none;
                        font-family: Calibri;

                      }
                    }

                    .boarding-point-time,
                    .dropping-point-time {
                      width: 20%;
                    }
                  }
                }
              }

              .selected-seat-fare-details {
                padding: 0px;

                ul {
                  li {
                    display: flex;
                    justify-content: space-between;
                    margin-top: -1%;

                    &:last-child {
                      border-top: 0.5px solid rgb(219, 219, 219);
                      // p {
                      //  }
                    }
                  }
                }

                .continue-booked-seat-btn {
                  text-align: right;

                  button {
                    height: auto;
                    background-color: #f0802d;
                    color: #fff;
                    border-radius: 5px;
                    padding: 10px 5px;
                    border: none;
                    font-size: 14px;
                    font-family: Calibri;

                    @include mobile {
                      padding: 4px 4px;
                    }


                    &:disabled {
                      background-color: #f0802d;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*************** seat layout styles **********/
.empty-row {
  padding: 5px;
}

.seats-layout-block {
  width: fit-content;
}

.seats-Vrlayout-block {
  display: flex;
  justify-content: space-between;
}

.seat-selection-block {
  padding: 15px;

  @include mobile {
    padding: 15px 10px;
  }

  .seat-layout-outer {
    margin: 10px 10px 10px 0;
    border: 1px solid #1c2b39;
    border-radius: 25px;
    padding: 10px 0;
    border-left: 5px solid #1c2b39;
    display: flex;

    @include mobile {
      border-left: 2px solid #1c2b39;
    }

    .seat-layout-title-block {
      .seat-layout-title-block-inner-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        border-right: 1px solid #1c2b39;
        // margin-right: 15px;
        justify-content: space-between;
        padding-bottom: 10px;

        @include mobile {
          margin-right: 0px;
          font-size: 10px;
        }
      }

      .seat-layout-title-text {
        p {
          margin-bottom: 0;
          transform: rotate(270deg);
        }
      }
    }

    .seater-seating-arrangement {
      height: 100%;

      @include mobile {
        margin-left: 0;
      }

      // .inner-seating-container {
      //   width: 100%;
      ul {
        display: flex;
        justify-content: flex-start;

        li {
          // margin-right: 5px;

          .seat-layout-container {
            background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
            height: 18px;
            width: 19px;
            border-radius: 0.3rem;
          }
        }
      }

      // }
      // .inner-sleeper-container {
      ul {
        display: flex;
        justify-content: flex-start;

        li {
          // margin-right: 5px;

          @include mobile {
            margin: 1px !important;
          }

          .sleeper-seat {
            background: url(../../../../assets/images/sleeper.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #d9d9d9;
            height: 30px;
            width: 69px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 4px auto;

            @include mobile {
              width: 34px;
              margin-left: 10%;
            }
          }

          .active {
            border: 1px solid #010052;
          }

          .booked-sleeper-type-image {
            background: url(../../../../assets/images/sleepergreen.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #a7f0bb;
            height: 30px;
            width: 69px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
              width: 34px;
              margin-left: 10%;
            }
          }

          .selected-sleeper-type-image {
            background: url(../../../../assets/images/sleeperblue.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            //  background-color: #010052;
            height: 30px;
            width: 69px;
            // border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;

            @include mobile {
              width: 34px;
              margin-left: 10%;
            }
          }

          .reserved-ladies-sleeper-type-image {
            background-image: url(../../../../assets/images/sleeperpink.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 69px;
            // background-color: #f2bfbe;
            // border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
              width: 34px;
              margin-left: 10%;
            }
          }

          .reserved-ladiesRes-sleeper-type-image {
            //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
            // border: 2px solid #f2bfbe;
            // background-color: #d9d9d9;
            // display: grid;
            // place-content: center;
            // height: 19px;
            // width: 40px;
            // border-radius: 5px;
            // font-size: 11px;
            // font-weight: 600;
            // color: #fff;
            // cursor: pointer;

            background: url(../../../../assets/images/sleeperred.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 69px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            // background-color: #f2bfbe;
            // border-radius: 5px;  
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
              width: 34px;
              margin-left: 10%;
            }
          }
        }
      }

      // }
    }
  }

  .sleeper-layout-outer {
    margin: 10px 10px 10px 0;
    border: 1px solid #1c2b39;
    border-radius: 25px;
    padding: 10px 0;
    border-left: 5px solid #1c2b39;
    display: flex;

    @include mobile {
      border-left: 2px solid #1c2b39;
    }

    .seat-layout-title-block {
      padding: 2px;

      .seat-layout-title-block-inner-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        border-right: 1px solid #1c2b39;
        margin-right: 0px;
        padding-bottom: 10px;

        @include mobile {
          margin-right: 0px;
          font-size: 10px;
        }
      }

      .seat-layout-title-text {
        p {
          margin-bottom: 0;
          transform: rotate(270deg);
        }
      }
    }

    .seater-seating-arrangement {
      height: 100%;

      ul {
        display: flex;
        justify-content: flex-start;

        li {
          margin: 0px;
          text-align: center;
          padding: 0px 4px;

          @include mobile {
            margin: 1px !important;
          }

          .seat-layout-container {
            background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
            height: 18px;
            width: 19px;
            border-radius: 0.3rem;
          }
        }
      }

      // }
      // .inner-sleeper-container {
      ul {
        display: flex;

        li {
          // margin: 5px;
          text-align: center;

          .sleeper-seat {
            background: url(../../../../assets/images/sleeper.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #d9d9d9;
            height: 30px;
            width: 69px;
            margin: 4px auto;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
              width: 34px;
              margin-left: 10%;
              font-size: 10px;
              font-weight: normal;
            }
          }

          .active {
            border: 1px solid #010052;
          }

          .booked-sleeper-type-image {
            background: url(../../../../assets/images/sleepergreen.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #a7f0bb;
            margin: 0px;
            height: 30px;
            width: 69px;
            margin: 4px auto;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
              width: 34px;
              margin-left: 10%;
              font-size: 10px;
              font-weight: normal;
            }
          }

          .selected-sleeper-type-image {
            background: url(../../../../assets/images/sleeperblue.png );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 30px;
            width: 69px;
            // background-color: #010052;
            //border-radius: 5px;
            margin: 4px auto;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;

            @include mobile {
              width: 34px;
              margin-left: 10%;
              font-size: 10px;
              font-weight: normal;
            }
          }

          .reserved-ladies-sleeper-type-image {
            background-image: url(../../../../assets/images/sleeperpink.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain; // background-color: #f2bfbe;
            height: 30px;
            width: 69px;
            // border-radius: 5px;
            margin: 4px auto;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
              width: 34px;
              margin-left: 10%;
              font-size: 10px;
              font-weight: normal;
            }
          }

          .available-seat-type-image {
            background-image: url(../../../../assets/images/seat.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #d9d9d9;
            height: 30px;
            width: 40px;
            margin: 4px auto;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
              width: 20px;
              font-weight: normal;
              font-size: 10px;
            }

            @include xsmobile {
              width: 18px;

              font-size: 8px;
            }
          }

          .selected-seat-type-image {
            background: url(../../../../assets/images/seatblue.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            // background-color: #010052;
            height: 30px;
            width: 40px;
            margin: 4px auto;
            border-radius: 5px 0 0 5px;
            font-size: 11px;
            font-weight: 600;
            color: rgb(0, 0, 0);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
              width: 20px;
              font-weight: normal;
              font-size: 10px;
            }

            @include xsmobile {
              width: 18px;
              font-size: 8px;
            }
          }
        }
      }

      // }
    }
  }
}

///vertical
/// 


.seat-vrlayout-outer {
  // margin: 10px 10px 10px 0;
  border: 1px solid #1c2b39;
  border-radius: 25px;
  // padding: 10px 0;
  border-top: 5px solid #1c2b39;
  // display: flex;
  margin-right: 10px;
  box-shadow: 1px 1px 4px grey;
  max-width: max-content;

  @include mobile {
    border-left: 2px solid #1c2b39;
  }

  .seat-layout-title-block {
    .seat-vrlayout-title-block-inner-data {
      display: flex;
      // flex-direction: column;
      // height: 100%;
      align-items: center;
      border-bottom: 1px solid #1c2b39;
      // margin-top: 15px;
      justify-content: space-around;
      // padding-bottom: 10px;

      @include mobile {
        margin-right: 0px;
        font-size: 10px;
      }

      .for-driving-vrhandel {
        background: url(../../../../assets/images/handel.png );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 2px solid transparent;
        width: 30px;
        height: 30px;
        transform: rotate(89deg);
      }

    }

    // .seat-layout-title-text {
    //   p {
    //     margin-bottom: 0;
    //     transform: rotate(270deg);
    //   }
    // }
  }

  .seater-Vrseating-arrangement {
    height: 100%;

    display: flex;
    justify-content: center;
    padding: 10px 4px;

    @include mobile {
      margin-left: 0;
    }

    .first-sleeper-row {
      width: 30%;
    }

    // .seat-layout-container {
    //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
    //   height: 18px;
    //   width: 19px;
    //   border-radius: 0.3rem;
    // }
    .inner-sleeper-container {
      width: 37px;

    }

    .sleeper-seat {
      background: url(../../../../assets/images/Vrsleeper.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #d9d9d9;
      height: 60px;
      width: 45px;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 34px;
        margin-left: 10%;
      }
    }

    .active {
      border: 1px solid #010052;
    }

    .booked-sleeper-type-image {
      background: url(../../../../assets/images/Vrsleepergreen.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #a7f0bb;
      height: 60px;
      width: 45px;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 34px;
        margin-left: 10%;
      }
    }

    .selected-sleeper-type-image {
      background: url(../../../../assets/images/Vrsleeperblue.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      //  background-color: #010052;
      height: 60px;
      width: 45px;
      // border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      @include mobile {
        width: 34px;
        margin-left: 10%;
      }
    }

    .reserved-ladies-sleeper-type-image {
      background-image: url(../../../../assets/images/Vrsleeperpink.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      width: 45px;
      // background-color: #f2bfbe;
      // border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        width: 34px;
        margin-left: 10%;
      }
    }

    .reserved-ladiesRes-sleeper-type-image {
      //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
      // border: 2px solid #f2bfbe;
      // background-color: #d9d9d9;
      // display: grid;
      // place-content: center;
      // height: 19px;
      // width: 40px;
      // border-radius: 5px;
      // font-size: 11px;
      // font-weight: 600;
      // color: #fff;
      // cursor: pointer;

      background: url(../../../../assets/images/Vrsleeperred.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      // background-color: #f2bfbe;
      // border-radius: 5px;  
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        width: 34px;
        margin-left: 10%;
      }
    }

    // .inner-seating-container {
    //   width: 100%;
    ul {
      display: flex;
      justify-content: flex-start;

      li {
        margin-right: 0px;

        .seat-layout-container {
          background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
          height: 18px;
          width: 19px;
          border-radius: 0.3rem;
        }
      }
    }

    // }
    // .inner-sleeper-container {
    ul {
      display: flex;
      justify-content: flex-start;

      li {
        margin-right: 0px;

        @include mobile {
          margin: 1px !important;
        }

        .sleeper-seat {
          background: url(../../../../assets/images/Vrsleeper.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #d9d9d9;
          height: 60px;
          width: 45px;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @include mobile {
            width: 34px;
            margin-left: 10%;
          }
        }

        .active {
          border: 1px solid #010052;
        }

        .booked-sleeper-type-image {
          background: url(../../../../assets/images/sleepergreen.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #a7f0bb;
          height: 30px;
          width: 69px;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @include mobile {
            width: 34px;
            margin-left: 10%;
          }
        }

        .selected-sleeper-type-image {
          background: url(../../../../assets/images/sleeperblue.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          //  background-color: #010052;
          height: 30px;
          width: 69px;
          // border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          cursor: pointer;

          @include mobile {
            width: 34px;
            margin-left: 10%;
          }
        }

        .reserved-ladies-sleeper-type-image {
          background-image: url(../../../../assets/images/sleeperpink.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 69px;
          // background-color: #f2bfbe;
          // border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mobile {
            width: 34px;
            margin-left: 10%;
          }
        }

        .reserved-ladiesRes-sleeper-type-image {
          //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
          // border: 2px solid #f2bfbe;
          // background-color: #d9d9d9;
          // display: grid;
          // place-content: center;
          // height: 19px;
          // width: 40px;
          // border-radius: 5px;
          // font-size: 11px;
          // font-weight: 600;
          // color: #fff;
          // cursor: pointer;

          background: url(../../../../assets/images/sleeperred.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 69px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          // background-color: #f2bfbe;
          // border-radius: 5px;  
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile {
            width: 34px;
            margin-left: 10%;
          }
        }
      }
    }

    // }
    .first-Vrsleeper-col {
      // display: flex;
      // height: 70px;

      .vertical_seat {
        // transform: rotate(90deg);
        padding-top: 0px;
        // width: 18px;
        // display: flex;
        // align-items: center;
      }
    }
  }
}

.sleeper-vrlayout-outer {
  margin: 10px 10px 10px 0;
  border: 1px solid #1c2b39;
  border-radius: 25px;
  padding: 10px 0;
  border-top: 5px solid #1c2b39;
  // display: flex;

  @include mobile {
    border-left: 2px solid #1c2b39;
  }

  .seat-layout-title-block {
    padding: 2px;

    .seat-layout-title-block-inner-data {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      border-right: 1px solid #1c2b39;
      margin-right: 0px;
      padding-bottom: 10px;

      @include mobile {
        margin-right: 0px;
        font-size: 10px;
      }
    }

    .seat-layout-title-text {
      p {
        margin-bottom: 0;
        transform: rotate(270deg);
      }
    }
  }

  .seater-seating-arrangement {
    height: 100%;

    .sleeper-seat {
      background: url(../../../../assets/images/Vrsleeper.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #d9d9d9;
      height: 60px;
      width: 45px;
      margin: 4px auto;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 34px;
        margin-left: 10%;
        font-size: 10px;
        font-weight: normal;
      }
    }

    .active {
      border: 1px solid #010052;
    }

    .booked-sleeper-type-image {
      background: url(../../../../assets/images/Vrsleepergreen.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #a7f0bb;
      margin: 0px;
      height: 60px;
      width: 45px;
      margin: 4px auto;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 34px;
        margin-left: 10%;
        font-size: 10px;
        font-weight: normal;
      }
    }

    .selected-sleeper-type-image {
      background: url(../../../../assets/images/Vrsleeperblue.png );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      width: 45px;
      // background-color: #010052;
      //border-radius: 5px;
      margin: 4px auto;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      @include mobile {
        width: 34px;
        margin-left: 10%;
        font-size: 10px;
        font-weight: normal;
      }
    }

    .reserved-ladies-sleeper-type-image {
      background-image: url(../../../../assets/images/Vrsleeperpink.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; // background-color: #f2bfbe;
      height: 60px;
      width: 45px;
      border-radius: 2px;
      // margin: 4px auto;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        width: 34px;
        margin-left: 10%;
        font-size: 10px;
        font-weight: normal;
      }
    }

    .available-seat-type-image {
      background-image: url(../../../../assets/images/Vrseat.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #d9d9d9;
      height: 30px;
      width: 40px;
      margin: 4px auto;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include mobile {
        width: 20px;
        font-weight: normal;
        font-size: 10px;
      }

      @include xsmobile {
        width: 18px;

        font-size: 8px;
      }
    }

    .selected-seat-type-image {
      background: url(../../../../assets/images/Vrseatblue.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // background-color: #010052;
      height: 30px;
      width: 40px;
      margin: 4px auto;
      border-radius: 5px 0 0 5px;
      font-size: 11px;
      font-weight: 600;
      color: rgb(0, 0, 0);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      // transform: rotate(90deg);

      @include mobile {
        width: 20px;
        font-weight: normal;
        font-size: 10px;
      }

      @include xsmobile {
        width: 18px;
        font-size: 8px;
      }
    }

    ul {
      display: flex;
      justify-content: flex-start;

      li {
        margin: 0px;
        text-align: center;
        padding: 0px 4px;

        @include mobile {
          margin: 1px !important;
        }

        .seat-layout-container {
          background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif);
          height: 18px;
          width: 19px;
          border-radius: 0.3rem;
        }
      }
    }

    // }
    // .inner-sleeper-container {
    ul {
      display: flex;

      li {
        margin: 5px;
        text-align: center;

        .sleeper-seat {
          background: url(../../../../assets/images/Vrsleeper.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #d9d9d9;
          height: 60px;
          width: 45px;
          margin: 4px auto;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @include mobile {
            width: 34px;
            margin-left: 10%;
            font-size: 10px;
            font-weight: normal;
          }
        }

        .active {
          border: 1px solid #010052;
        }

        .booked-sleeper-type-image {
          background: url(../../../../assets/images/Vrsleepergreen.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #a7f0bb;
          margin: 0px;
          height: 60px;
          width: 45px;
          margin: 4px auto;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @include mobile {
            width: 34px;
            margin-left: 10%;
            font-size: 10px;
            font-weight: normal;
          }
        }

        .selected-sleeper-type-image {
          background: url(../../../../assets/images/Vrsleeperblue.png );
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 69px;
          // background-color: #010052;
          //border-radius: 5px;
          margin: 4px auto;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          cursor: pointer;

          @include mobile {
            width: 34px;
            margin-left: 10%;
            font-size: 10px;
            font-weight: normal;
          }
        }

        .reserved-ladies-sleeper-type-image {
          background-image: url(../../../../assets/images/Vrsleeperpink.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; // background-color: #f2bfbe;
          height: 30px;
          width: 69px;
          // border-radius: 5px;
          margin: 4px auto;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mobile {
            width: 34px;
            margin-left: 10%;
            font-size: 10px;
            font-weight: normal;
          }
        }

        .available-seat-type-image {
          background-image: url(../../../../assets/images/Vrseat.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #d9d9d9;
          height: 30px;
          width: 40px;
          margin: 4px auto;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          @include mobile {
            width: 20px;
            font-weight: normal;
            font-size: 10px;
          }

          @include xsmobile {
            width: 18px;

            font-size: 8px;
          }
        }

        .selected-seat-type-image {
          background: url(../../../../assets/images/Vrseatblue.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          // background-color: #010052;
          height: 30px;
          width: 40px;
          margin: 4px auto;
          border-radius: 5px 0 0 5px;
          font-size: 11px;
          font-weight: 600;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          // transform: rotate(90deg);

          @include mobile {
            width: 20px;
            font-weight: normal;
            font-size: 10px;
          }

          @include xsmobile {
            width: 18px;
            font-size: 8px;
          }
        }
      }
    }

    // }
  }
}



.reserved-ladiesRes-sleeper-type-image {
  //   background: url(../../../../assets/images/seatlayout.e1b07d89842b6fbdbcd8.gif) -39px -19px;
  // border: 2px solid #f2bfbe;
  // background-color: #d9d9d9;
  // display: grid;
  // place-content: center;
  // height: 19px;
  // width: 40px;
  // border-radius: 5px;
  // font-size: 11px;
  // font-weight: 600;
  // color: #fff;
  // cursor: pointer;

  background: url(../../../../assets/images/sleeperred.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 69px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // background-color: #f2bfbe;
  // border-radius: 5px;  
  font-size: 11px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    width: 34px;
    margin-left: 10%;
  }
}
.first-seater-row{
  li{
    min-width: 40px;
  }
}
/*********** Seat Type Description **********/
.seat-type-description {

  // .first-seater-row{
  //   .Horiz_seat{
  //     padding: 4px 1px;
  //   }
  // }
  ul {
    display: flex;
    justify-content: flex-start;

    li {
      text-align: center;
      margin: 5px;

      .available-seat-type-image {
        background-image: url(../../../../assets/images/seat.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        //background-color: #d9d9d9;
        height: 30px;
        width: 40px;
        margin: 4px auto;
        // border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }

        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }

      .active {
        border: 1px solid #010052;
      }

      .Booked-seat-type-image {
        background: url(../../../../assets/images/seatgreen.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // background-color: #a7f0bb;
        height: 30px;
        width: 40px;
        border-radius: 5px 0 0 5px;
        margin: 4px auto;
        font-size: 11px;
        font-weight: 500;
        color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: space-evenly;


        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }

        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }

      .selected-seat-type-image {
        background: url(../../../../assets/images/seatblue.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // background-color: #010052;
        margin: 4px auto;
        height: 30px;
        width: 40px;
        margin: 4px auto;
        border-radius: 5px 0 0 5px;
        font-size: 11px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }

        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }

      .ladies-reserved-seat-type-image {
        background: url(../../../../assets/images/seatpink.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // background-color: #f2bfbe;
        margin: 4px auto;
        height: 30px;
        width: 40px;
        border-radius: 5px 0 0 5px;
        margin: 4px auto;
        font-size: 11px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        cursor: pointer;

        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }

        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }


      // .seat-selection-block .sleeper-layout-outer .seater-seating-arrangement ul li {
      //   border: 2px solid black;
      // }

      .ladiesRes-reserved-seat-type-image {
        background: url(../../../../assets/images/seatred.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        //border: 2px solid #f2bfbe;
        //background-color: #d9d9d9;
        display: grid;
        place-content: center;
        height: 30px;
        width: 40px;
        margin: 0 auto;
        border-radius: 5px 0 0 5px;
        margin: 4px auto;
        font-size: 11px;
        font-weight: 600;
        color: #ff0000;
        cursor: pointer;

        @include mobile {
          width: 20px;
          font-weight: normal;
          font-size: 10px;
        }

        @include xsmobile {
          width: 18px;

          font-size: 8px;
        }
      }

      p {
        margin-bottom: 0;
        text-align: center;
        color: #757575;
        font-size: 0.8rem;
      }
    }
  }

  .space_between {
    justify-content: space-evenly;
  }
}

/*** vertical seat***/

.Horiz_seat1 {
  // margin-right: 70px;

  // padding: 0px 4px !important;
  .sleeper-seat {
    background: url(../../../../assets/images/sleeper.png ) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    // background-color: #d9d9d9;
    height: 30px !important;
    width: 75px !important;
    margin: 4px auto !important;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.available-vrseat-type-image {
  background-image: url(../../../../assets/images/Vrseat.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  width: 40px;
  // margin: 2px auto;
  font-size: 12px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  // transform: rotate(90deg);
  justify-content: space-evenly;

  @include mobile {
    width: 20px;
    font-weight: normal;
    font-size: 10px;
  }

  @include xsmobile {
    width: 18px;

    font-size: 8px;
  }
}

.active {
  border: 1px solid #010052;
}

.Booked-Vrseat-type-image {
  background: url(../../../../assets/images/Vrseatgreen.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // background-color: #a7f0bb;
  height: 35px;
  width: 40px;
  border-radius: 5px 0 0 5px;
  // margin: 4px auto;
  font-size: 11px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // transform: rotate(90deg);


  @include mobile {
    width: 20px;
    font-weight: normal;
    font-size: 10px;
  }

  @include xsmobile {
    width: 18px;

    font-size: 8px;
  }
}

.selected-Vrseat-type-image {
  background: url(../../../../assets/images/Vrseatblue.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // background-color: #010052;
  // margin: 4px auto;
  height: 35px;
  width: 40px;
  // margin: 4px auto;
  border-radius: 5px 0 0 5px;
  font-size: 11px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // transform: rotate(90deg);

  @include mobile {
    width: 20px;
    font-weight: normal;
    font-size: 10px;
  }

  @include xsmobile {
    width: 18px;

    font-size: 8px;
  }
}

.ladies-reserved-seat-type-image {
  background: url(../../../../assets/images/Vrseatpink.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35px;
  width: 40px;
  font-size: 12px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  @include mobile {
    width: 20px;
    font-weight: normal;
    font-size: 10px;
  }

  @include xsmobile {
    width: 18px;

    font-size: 8px;
  }
}


// .seat-selection-block .sleeper-layout-outer .seater-seating-arrangement ul li {
//   border: 2px solid black;
// }

.ladiesRes-reserved-seat-type-image {
  background: url(../../../../assets/images/Vrseatred.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  //border: 2px solid #f2bfbe;
  //background-color: #d9d9d9;
  display: grid;
  place-content: center;
  height: 35px;
  width: 40px;
  margin: 0 auto;
  border-radius: 5px 0 0 5px;
  // margin: 4px auto;
  font-size: 11px;
  font-weight: 600;
  color: #ff0000;
  cursor: pointer;

  @include mobile {
    width: 20px;
    font-weight: normal;
    font-size: 10px;
  }

  @include xsmobile {
    width: 18px;

    font-size: 8px;
  }
}

p {
  margin-bottom: 0;
  // text-align: center;
  color: #757575;
  font-size: 0.8rem;
}

.space_between {
  justify-content: space-evenly;
}



.vertical_seat1 {
  // transform: rotate(90deg);
  padding-top: 4px;
  margin-top: -40px;

  // width: 18px;
  // display: flex;
  // align-items: center;
  .sleeper-seat {
    background: url(../../../../assets/images/Vrsleeper.png) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    // background-color: #d9d9d9;
    height: 75px !important;
    width: 30px !important;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 4px auto;

    @include mobile {
      width: 34px;
      margin-left: 10%;
    }
  }

}

// .vertical_Colseat{
//   padding-top: 0px;
//   width: 18px;
// }

.no-pointer-events {
  pointer-events: none;
}

/* 04-12-2020-css */

.search-point-boarding {
  display: flex;
}

.boarding-point-search {
  padding-left: 10px;
}

.search-point-boarding h4 {
  padding-top: 5px;
}

.boarding-point-search .form-control {
  height: 30px !important;
  width: 64%;
}

@media screen and (max-width: 768px) {
  .search-point-boarding {
    display: block;
  }

  .search-point-boarding h4 {
    padding-top: 0px;
  }

  .boarding-point-search {
    padding-left: 0px;
  }

  ul.first-sleeper-row li {
    padding: 0px 5px 0px 0px !important;
  }
}

.empty_seat_sleeper {
  height: 30px !important;
  width: 69px !important;
}

ul.first-sleeper-row li {
  padding: 0px 26px 0px 0px;
  margin-left: 27px;
}

@media screen and (max-width: 768px) {
  .seater-seating-arrangement ul li {
    padding: 0px 2px !important;
  }
}

.steering-icon {
  border: 2PX SOLID transparent;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;
}

.for-driving-handel {
  background: url(../../../../assets/images/handel.png );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;

}

.for-driving-seat {
  background: url(../../../../assets/images/seat.png );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;
}

.for-driving-handel1 {

  border: 2px solid transparent;
  width: 30px;
  height: 30px;
}

.boardingpoint-content {
  color: black;
  margin-top: 0%;
  border: 2px solid transparent;
  line-height: 80%;
  font-size: 12px;
  font-style: Calibri;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.abc {

  opacity: 0.5;
  color: #000;
}

.box-for-amenities {
  border: 2px solid transparent;
  width: 95%;
  margin-top: -4%;
}

.box-for-amenities1 {
  border: 2px solid transparent;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.all-icons-done {
  width: 20%;
  height: 5vh;
  margin-bottom: 6%;
}

.close-button {
  border: 0.1px solid rgb(168, 165, 165);
  background-color: white;
  margin-top: 0.6%;
  margin-right: 0.2%;
  font-size: small;
  padding: 0.2%;
  border-radius: 15px;
}

.vr_seat {
  height: 60px;
  width: 45px;
}

.H_seat {
  height: 35px;
  width: 40px;
}

.empty-v {
  padding: 2px 0;
}

.active {
  // border: 1px solid #010052;
  border: 1px solid #c4c4c4;
  border-top: none;
}