@import "../../assets/variableStyles.scss";

.full-body {
    border: 2px solid transparent;
    background-color: #fafafa;

    @include mobile {
        width: 100%;
    }
}


.graph-no1 {
    border: 2px solid transparent;
    background-color: transparent;

    @include mobile {
        width: 100%;
        height: 100%;
    }
}

.pie-chart-box {
    border: 2px solid transparent;

    @include mobile {
        height: 370px;
        padding-top: 5%;
    }
}

.checking-pie-chart {
    border: 2px solid black;
    background-color: blue;
    width: 10%;
}

.test-1-graph2 {
    .recharts-surface {
        border: 2px solid transparent;
        height: 325px;

        @include mobile {
            height: 100%;
        }
    }
}

.test-1-graph {
    border: 2px solid transparent;
    background-color: white;
    box-shadow: 2px 2px 10px rgb(201, 201, 201);
    width: 48%;
    margin: 1%;
    border-radius: 10px;

    @include mobile {
        width: 100%;
        height: 100%;
    }
}

.test-1-graph1 {
    border: 2px solid transparent;
    background-color: white;
    box-shadow: 2px 2px 10px rgb(201, 201, 201);
    width: 48%;
    margin: 1%;
    border-radius: 10px;
    text-align: center;

    @include mobile {
        width: 100%;
    }
}

.test-1-graph1 {
    .ant-tabs-nav-list {
        margin-left: 87%;

        @include mobile {
            margin-left: 80%;
        }
    }
}

.test-1-graph1 {
    .ant-tabs-tab {
        margin: 0 12px 0 0;
    }
}

.test-1-graph2 {
    border: 2px solid transparent;
    background-color: white;
    width: 98%;
    margin: 1%;
    border-radius: 10px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;

    @include mobile {
        width: 100%;
    }
}

.top-bus-routs {
    border: 2px solid black;
}


.table-form-css {
    border: 2px solid transparent;
    width: 100%;
    box-shadow: none;
}


.info-outer-main-box {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    margin-top: 2%;

    @include mobile {
        margin-top: 0;
    }

}

.info-outer-main-box1 {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    margin-top: 2%;
}

.info-main-box {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    margin-top: -1.5%;
    margin-left: 0.2%;
    width: 49%;

    @include mobile {
        margin-top: 2%;
        width: 100%;
    }
}

.info-main-box4 {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;

    @include mobile {
        margin-top: 2%;
    }
}

.info-main-box41 {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    // margin-top: -2%;

    @include mobile {
        margin-top: 2%;
    }
}


.info-main-box1 {
    border: 0.5px solid transparent;
    background-color: white;
    box-shadow: 2px 2px 10px rgb(201, 201, 201);
    width: 32%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    border-radius: 7px;

    @include mobile {
        width: 100%;
        margin: 2%;
    }
}

.info-main-box2 {
    border: 2px solid transparent;
    width: 30%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
}

.info-main-box-img {
    border: 2px solid transparent;
    width: 90%;
    text-align: center;
    border-radius: 50%;
    padding-top: 10%;

}


.info-main-box3 {
    border: 2px solid transparent;
    width: 60%;
    color: rgb(138, 138, 138);
}

.image-css {
    width: 100%;
}

.form-for-dashboard-main-box {
    border: 2px solid transparent;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    width: 98%;
    margin: auto;


}

.form-for-dashboard1 {
    border: 2px solid transparent;
    width: 50%;
    text-align: right;
}

.form-for-dashboard {
    border: 2px solid transparent;
    width: 50%;
    padding-left: 1%;

    @include mobile {
        width: 100%;
    }
}

.table-formt-css {
    border: 2px solid transparent;
    width: 47%;
    margin-left: 1%;
    border-radius: 10px;
}

.nav-dashboard {
    border: 1px solid rgb(214, 214, 214);
    background-color: white;
    border-radius: 10px;
    width: 97.5%;
    margin: auto;

}

.nav-dashboard1 {
    border: 2px solid transparent;
    width: 37%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    margin-left: 4%;

    @include mobile {
        width: 100%;
        margin-left: 0%;
    }

    @include tab {
        width: 100%;
        margin-left: 0%;
    }
}

.nav-dashboard2 {
    border: 2px solid transparent;
    padding-right: 0%;
    width: 25%;
    padding-top: 1.5%;

    @include mobile {
        width: 30%;
    }
}

.nav-dashboard3 {
    border: 2px solid transparent;
    width: 34%;
    padding-top: 1.5%;
    visibility: visible;

    @include mobile {
        width: 40%;
        visibility: hidden;
    }
}

.nav-dashboard4 {
    border: 2px solid transparent;
    width: 34%;
    padding-top: 1.5%;
    visibility: visible;

    @include mobile {
        width: 20%;
        margin-top: 1%;
        visibility: hidden;
    }
}

.span-arrow:after {
    content: ' \003E';
    color: black;
    margin-right: 4%;
}

.for-arrow-sign::after {
    content: ' \003E';
    color: black;
    margin-left: 4%;
}

.for-arrow-sign {
    color: #737373;
}

.for-lign-after {
    border-right: 2px solid rgb(180, 179, 179);
    padding-right: 8%;
    font-size: large;
    color: black;
}

.main-tab-buttons {
    border: 2px solid transparent;
}

.set-header-css {
    border: 2px solid transparent;
    width: 30%;
    margin: auto;
    margin-top: -8%;
    margin-bottom: 4%;

    @include mobile {
        width: 45%;
        margin-top: -15%;
        margin-bottom: 5%
    }
}

.for-font-looksize {
    font-size: 12px;
}

.info-main-box3 {
    .analytic-line1 {
        .ant-progress-bg {
            background-color: red;
        }
    }
}

.info-main-box3 {
    .analytic-line2 {
        .ant-progress-bg {
            background-color: blue;
        }
    }
}

.info-main-box3 {
    .analytic-line3 {
        .ant-progress-bg {
            background-color: green;
        }
    }
}

.info-main-box3 {
    .analytic-line4 {
        .ant-progress-bg {
            background-color: orange;
        }
    }
}

.info-main-box3 {
    .analytic-line5 {
        .ant-progress-bg {
            background-color: skyblue;
        }
    }
}

.info-main-box3 {
    .analytic-line6 {
        .ant-progress-bg {
            background-color: purple;
        }
    }
}

.info-main-box3 {
    .ant-progress-inner {
        background-color: #dfdfdf;
    }
}