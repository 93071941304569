@import "../../assets/variableStyles.scss";

.login-container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .backgroundLogin {
    background-image: url("../../assets/images/etravosLogin.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    height: 350px;
    border-radius: 10px;
  }
  .login-wrapper {
    // padding: 30px;
    margin: 30px;
    .login-card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border: 1px;
      border-radius: 10px;
      .ant-form-item-control {
        input,
        span {
          border: 1px solid #efefef !important;
          border-radius: 4px !important;
          box-shadow: 0px 2px 2px rgbg(0 0 0, 0.05);
          color: #8d9190;
        }
      }
    }
    .otp-img {
      width: 110px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .otp-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 16px;
      border: 0px;
      border-radius: 8px;
      font-weight: 600;
      background-color: #f0802d;
      color: #ffffff;
      height: 48px;
      font-size: 14px;
      margin-top: 10px;
      &:hover {
        background-color: #f0802d !important;
        color: #ffffff !important;
      }
      &:focus {
        background-color: #f0802d !important;
        color: #ffffff !important;
      }
    }
    .google-btn {
      width: 100%;
      padding: 0px 16px;
      border-radius: 8px;
      font-weight: 600;
      height: 48px;
      font-size: 14px;
      background-color: #05004e !important;
      color: #fff !important;
      border-color: #05004e !important;
    }
    .google-btn:hover {
      color: #ffffff !important;
      border-color: #05004e !important;
      background-color: #05004e !important;
    }
  }
}

.modal-login-new-1 {
  border-radius: 8px;
  width: 368px !important;
  height: auto;
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-close-x {
    color: #000 !important;
  }
  .otp-shadow {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 30px 30px 6px 30px;
    background: #fff;
    margin-bottom: 20px;
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  .otp-margin-bottom {
    .ant-input {
      letter-spacing: 2.5px;
      font-weight: 600;
      text-align: center;
      border-color: #efefef !important;
      color: #434343;
      box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: #707070;
      }
    }
  }
  .google-btn {
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    height: 48px;
    font-size: 14px;
    background-color: #05004e !important;
    color: #fff !important;
    border-color: #05004e !important;
  }
  .google-btn:hover {
    color: #ffffff !important;
    border-color: #05004e !important;
    background-color: #05004e !important;
  }
  .otp-img-1 {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .otp-shadow-1 {
    padding: 5px;
  }
  .otp-shadow-value {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 9px;
    background: #fff;
    margin-bottom: 3px;
  }
  .otp-img {
    width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-modal-footer {
    display: none;
  }
  .countdown {
    text-align: center;
  }
}
.modal-login-new-1 .ant-statistic-content-value {
  color: #f0802d !important;
}
.bc-admin-21 h4 {
  font-size: 50px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0px;
}
.bc-admin-21 h5 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0px;
  color: #000 !important;
}
.bc-admin-21 p {
  text-align: center;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
}
.login-otp-img {
  position: absolute;
  right: -24px;
  top: -27px;
  width: 104px;
  border-top-right-radius: 4px;
}
.bc-admin-21 {
  padding-top: 10px;
  padding-bottom: 2px;
}
@media screen and (max-width: 768px) {
  .login-direction {
    flex-direction: column;
  }
  .login-width-panel {
    width: 100% !important;
  }
}
.login-width-panel {
  width: 1300px;
  margin: 0 auto;
}
.otp-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  border: 0px;
  border-radius: 8px;
  font-weight: 600;
  background-color: #f0802d;
  color: #ffffff;
  height: 48px;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    background-color: #f0802d !important;
    color: #ffffff !important;
  }
  &:focus {
    background-color: #f0802d !important;
    color: #ffffff !important;
  }
}
