
.contact-us-section {
    background-color: #1c2b39;
    padding: 30px 0px;
    h2 {
        color: #eaebee;
        font-size: 27px;
        line-height: 35px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0px !important;
    }
    .contact-heading {
        text-align: center;
        color: #dcdee3;
        p {
            font-family: Ubuntu-Light,Ubuntu Light,Ubuntu,sans-serif;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            padding-top: 20px;
        }
    }
    .contact-form-section {
        max-width: 86%;
        margin: auto;
        .contact-form {
            .ant-form-item-label {
                text-align: left !important;
            }
            .click-modal {
                margin-top: 20px;
                a {
                    color: #1890ff;
                    padding-left: 5px;
                }
            }
        }
        .phone-number {
            width: 100%;;
            .phone-select-prefix {
                width: 40%;
            }
            .phone-select-suffix {
                width: 60%;
            }
        }
        .phone-select-options {
            display: flex !important;
            justify-content: space-between !important;
        }
        .send-msg-btn {
            color: #fff;
            background-color: #0775e2;
            font-family: Ubuntu,sans-serif;
            /* margin-top: 8px; */
            /* margin-bottom: 4px; */
            min-width: 150px;
            font-size: 16px;
            padding: 20px 20px;
            line-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            letter-spacing: 1px;
            border-radius: 3px;
            border: none;
        }
        .contact-form-text {
            font-size: 12px;
            font-size: 12px;
            line-height: 1.5;
            color: #969dac;
            margin-bottom: 0px !important;
        }
    }
}

.acc-head-section {
    display: flex;
    margin-bottom: 18px;
    margin-top: 18px;
    align-items: center;
    .acc-heading {
        display: flex !important;
        align-items: center;
        width: 20%;
        .info-icon {
            margin-right: 20px;
            font-size: 20px;
            margin-bottom: 8px;
        }
        h6 {
            margin-bottom: 0px !important;
            font-size: 15px;
            font-weight: 600;
        }
    }
    .hr-block {
        width: 80%;
    }
    hr {
        width: 100%;
    }
}